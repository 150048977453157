import React, { Component, Fragment } from 'react';
import { Typography, Button, Grid, Avatar, Snackbar, TextField, MenuItem, Checkbox, createMuiTheme, MuiThemeProvider, Select, Chip, Input, OutlinedInput, FormControl, InputLabel, InputAdornment, FormHelperText} from '@material-ui/core';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import styles from './styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {withStyles} from "@material-ui/core/styles/index";
import validatorjs from 'validatorjs';
import {format, lastDayOfMonth, startOfWeek, lastDayOfWeek, startOfMonth, endOfMonth, subMonths, subWeeks, addWeeks, addMonths} from 'date-fns';
import MySnackbarContentWrapper from './../../../components/Notification/snackbar';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Photo from './../../../assets/user.png';
import API, {configService, parseErrors, schedularService, APIROOT, getUserData } from "../../../constants";
import {NotificationManager} from "react-notifications";
import difference from "../../../utils/diff";
import { countries } from './../../../utils/countries';

const state = observable({
    data:{},
    isError:false,
    errorMsg:'',
    roleid : 0
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#27abe9',
        },
        secondary: {
            main: '#27abe9',
        },
    }
});

const rules = {
    phone: {
        function: (value) => value.match(/^\+[1-9]{1,3}[0-9]{3,14}$/),
        message: 'Not valid phone number.',
    },
    password_regex: {
        // function: (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/),
        // message: 'Password must contain uppercase, lowercase and digit.'
        function: (value) => {return value.length >= 6},
        message: 'Password must be at least 6 characters long.'

    },
};

const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator, form }) => {
            Object.keys(rules).forEach((key) =>
                validator.register(key, rules[key].function, rules[key].message));
        },
    }),
};


const fields = [
    {
        name: 'old_password',
        type:'password',
        label: 'Old Password',
        placeholder: 'Old Password',
        rules: 'required|string|min:6',
    }
    ,{
        name: 'new_password',
        placeholder: 'New Password',
        label: 'New Password',
        type:'password',
        rules: 'required|string|password_regex',
    },
    {
        name: 'confirm_password',
        placeholder: 'Confirm Password',
        label: 'Confirm Password',
        type:'password',
        rules: 'required|string|same:new_password',
    }];

const hooks = {
    onError(form) {
        console.log('All form errors', form.get());
    }
};

const form = new MobxReactForm({ fields }, { plugins });

class ChangePassword extends Component{
    constructor(props){
        super(props);
        this.userData = getUserData();
        this.state = {

        };
    }


    componentDidMount() {
        let currentComponent = this;
        let localData = JSON.parse(localStorage.getItem('userData'));
        state.roleid = localStorage.role;
    }

    handleUpdatePassword = () => {
        let params = {
            old_password: form.$('old_password').value,
            new_password: form.$('new_password').value
        };

        let c_password = form.$('confirm_password').value;
        if(c_password !== params.new_password){
            NotificationManager.error("Confirm Password does not match Password");
        }
        else if(params.old_password.length === 0 || params.new_password.length===0){
            NotificationManager.error("Old Password and New Password is required");
        }
        else{
            API.POST(params, APIROOT, API.URLS.changePassword, (res) => {
                if(res.status == 200){
                    this.componentDidMount();
                    NotificationManager.success('Successfully Updated!');
                    form.clear();
                    this.props.closeCallBack();
                }else if(res.status == 400){
                    let error = parseErrors(res);
                    for (let key in error){
                        if(key === "new_password"){
                            form.$(key).invalidate(res.data.new_password.join('. '));
                        }
                        if(key === "old_password"){
                            form.$(key).invalidate(error[key]);
                        }
                        // else{
                        //     state.isError = true;
                        //     state.errorMsg = error[key];
                        // }
                    }
                }
            });
        }
    }

    render(){
        const { classes } = this.props;
        const {isError, errorMsg, roleid} = state;
        return(
            <Fragment>
            {isError ?
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={isError}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant="error"
                            message={ errorMsg }
                        />
                    </Snackbar>:null}
                <MuiThemeProvider theme={theme}>
                <Grid
                        className={classes.spacingWrap}
                        container
                        justify={'flex-start'}
                        direction={'column'}
                        style={{overflow:'scroll', margin:'0px 0px 0px 30px'}}
                        className={classes.formContainer}
                    >
                    <Grid container style={{width:'100%'}}>
                        <Grid item xs><Typography className={classes.headerText}>Change Password</Typography></Grid>
                        <Grid item xs>
                            <Button
                                className={classes.saveBtn}
                                onClick={this.handleUpdatePassword}
                            >Save</Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container alignItems={'center'} justify={'flex-start'} direction={'row'} style={{marginBottom:'10px'}}>
                            <div className={classes.subHeaderText}>ENTER DETAILS</div><div className={classes.divider}/>
                        </Grid>

                        <Grid container className={classes.spacing}>
                            <Grid item xs={4}>
                              <TextField
                                    label={'Old Password'}
                                    type={'password'}
                                    placeholder={'Old Password'}
                                    className={classes.textField}
                                    classes={{root:classes.textFieldRoot}}
                                    inputProps={{

                                    }}
                                    {...form.$('old_password').bind()}
                                    error={!!form.$('old_password').error}
                                    helperText={form.$('old_password').error}
                                    variant={'outlined'}
                              />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label={'New Password'}
                                    type={'password'}
                                    placeholder={'New Password'}
                                    className={classes.textField}
                                    classes={{root:classes.textFieldRoot}}
                                    inputProps={{

                                    }}
                                    {...form.$('new_password').bind()}
                                    error={!!form.$('new_password').error}
                                    helperText={form.$('new_password').error}
                                    variant={'outlined'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label={'Confirm Password'}
                                    type={'password'}
                                    placeholder={'Confirm Password'}
                                    className={classes.textField}
                                    classes={{root:classes.textFieldRoot}}
                                    inputProps={{

                                    }}
                                    {...form.$('confirm_password').bind()}
                                    error={!!form.$('confirm_password').error}
                                    helperText={form.$('confirm_password').error}
                                    variant={'outlined'}
                                />
                            </Grid>
                        </Grid>   
                    </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Fragment>
        )
    }
}

export default withStyles(styles)(observer(ChangePassword));