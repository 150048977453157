import React,{ lazy, Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Geocode from "react-geocode";
import './App.css';

import OnlyVerified from './components/auth';
import MiniDrawer from './components/sidebar';
import Loader from './components/loader';

const ForgotPassword = lazy(()=>import ('./views/PasswordReset/forgotPassword'));
const Verification =  lazy(()=> import('./views/PasswordReset/verification'));
const ResetPassword = lazy(()=> import( './views/PasswordReset/resetPassword'));
const Configuration = lazy(()=>import( './views/Configurations/configurations'));
const Reports = lazy(()=> import('./views/Reports/reports'));
const LoginOnlyLandingPage = lazy(()=>import('./views/SignUp/loginOnlyLandingPage'));
// import CustomModal from './components/Modal/modalX';
const SignUp = lazy(()=>import('./views/SignUp/signUp'));
// import AddAccount from './views/Configurations/Account/addAccount';
// import EditBrand from './views/Configurations/Brand/editBrand';
// import AddPOI from './views/Configurations/Poi/addPoi';
// import EditPOI from './views/Configurations/Poi/editPoi';
// import AttendanceHeader from './views/Attendance/attendance_header';
const TimeTracker = lazy(()=>import('./views/Attendance/timetracker'));
// import DashGreetings from './views/Dashbord/dashboardGreetings';
// import DashData from './views/Dashbord/dashboardData';
const AccountSetup = lazy(()=> import( './views/Dashbord/accountSetup'));
const Confirmation = lazy(()=>import( './views/Tools/confirmation'));
const DashBoard = lazy(()=>import('./views/Dashbord/dashboard'));
const Schedular = lazy(()=>import('./views/Schedular/schedular'));
const Attendance = lazy(()=>import('./views/Attendance/attendance'));
const Tools = lazy(()=> import('./views/Tools/tools'));
// import Chat from './views/Chat/chat';
const Chat = lazy(()=>import('./views/Chat/new_chat'));
const Payroll = lazy(()=>import('./views/Payroll/payroll'));

// import {NotificationManager} from 'react-notifications';
// import API, {chatWs} from "./constants";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
// import 'react-big-scheduler/lib/css/style.css'




Geocode.setApiKey("AIzaSyDub18Mj5tOpVqdbcY7U6FULr1VZC3fNr0");

function NotFound() {
    return(
        <div style={{ textAlign:"Center", fontSize:20 }}>404 - Page Not Found</div>
    )
}

export const fakeAuth = {
    isAuthenticated: false,
    authenticate(cb) {
        fakeAuth.isAuthenticated = true;
        setTimeout(cb.push('/dashboard'), 100); // fake async
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        setTimeout(cb.push('/'), 100);
    }
};

const Refresh = ({ path = '/' }) => (
    <Route
        path={path}
        component={({ history, location, match }) => {
            history.replace({
                ...location,
                pathname:location.pathname.substring(match.path.length)
            });
            return null;
        }}
    />
);
const FallBack = ()=>(
    <div>loading ...</div>
)

// const customTheme = {
//     leave:'#EF7272',
//     absent:'#FAC97B',
//     off_day:'#EB84B8',
//     busy:'#CBB9F8',
//     holiday:'#A2CB74',
//     overlapping:'#70d2fe'
// }

function App() {
  return (
      <Router>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <Switch>
                    
                    <Route exact path={"/settings"}
                        render={(props)=> <OnlyVerified {...props}><MiniDrawer {...props} screen={'Settings'} 
                        component={<Suspense fallback={<FallBack/>}><Configuration {...props } screen={'Settings'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/dashboard"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Dashboard'}
                        component={<Suspense fallback={<FallBack/>}><DashBoard {...props} screen={'Dashboard'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/selectpackage"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Select Package'} 
                        component={<Suspense fallback={<FallBack/>}><AccountSetup {...props}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/timetracker"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Time Tracker'} 
                        component={<Suspense fallback={<FallBack/>}><TimeTracker {...props} screen={'Time Tracker'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/confirmation"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Confirmation'} 
                        component={<Suspense fallback={<FallBack/>}><Confirmation {...props} screen={'Confirmation'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/scheduler"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Schedular'} 
                        component={<Suspense fallback={<FallBack/>}><Schedular {...props} screen={'Scheduler'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/tools"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Tools'} 
                        component={<Suspense fallback={<FallBack/>}><Tools {...props} screen={'Tools'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/workchat"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Workchat'}
                        component={<Suspense fallback={<FallBack/>}><Chat {...props} screen={'Workchat'}/></Suspense>}/></OnlyVerified>}
                    />

                    <Route exact path={"/attendance"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Attendance'} 
                        component={<Suspense fallback={<FallBack/>}><Attendance {...props} screen={'Attendance'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/attendance/:id"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Attendance'} 
                        component={<Suspense fallback={<FallBack/>}><Attendance {...props} screen={'Attendance'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/payroll"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Payroll'} 
                        component={<Suspense fallback={<FallBack/>}><Payroll {...props} screen={'Payroll'}/></Suspense>}/></OnlyVerified>}
                    />
                    <Route exact path={"/reports"}
                        render={(props)=><OnlyVerified {...props}><MiniDrawer {...props} screen={'Reports'} 
                        component={<Suspense fallback={<FallBack/>}><Reports {...props} screen={'Reports'}/></Suspense>}/></OnlyVerified>}
                    />

                    <Route exact path={"/"} 
                        render={(props)=>(<Suspense fallback={<FallBack/>}><SignUp {...props}/></Suspense>)}/>

                    <Route exact path={"/login"} 
                        render={(props)=>(<Suspense fallback={<FallBack/>}><LoginOnlyLandingPage {...props}/></Suspense>)}/>

                    <Route exact path={"/forgot-password"} 
                        render={(props)=><Suspense fallback={<FallBack/>}><ForgotPassword {...props}/></Suspense>}/>
                    <Route exact path={"/verification"} 
                        render={(props)=><Suspense fallback={<FallBack/>}><Verification {...props}/></Suspense>} />
                    <Route exact path={"/reset-password"} 
                        render={(props)=><Suspense fallback={<FallBack/>}><ResetPassword {...props}/></Suspense>} />
                    {/*<Route exact path={"/dashboard"} component={MiniDrawer} />*/}
                    <Refresh path="/refresh" />
                    <Route component={NotFound}/>
                    
                </Switch>
            </div>
            {/* <ToastMessage /> */}
            <Loader />
            <NotificationContainer/>
          </MuiPickersUtilsProvider>

      </Router>
  );
}

export default App;


