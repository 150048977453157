import API,{ APIROOT } from "./constants";
import axios from 'axios';
import {available_permission} from './utils/availablePermissions';


export const removeNull = function(data){
    if (data){
        return data;
    }
    else{
        return '';
    }

}

export const checkPermissions = function(...permissions){
    // console.log(permissions);
    let allUserPermissions = localStorage.getItem('permissions').split(',');
    let flag  = true;
    for (let i = 0;i<permissions.length;i++){
        if (allUserPermissions.indexOf(available_permission[permissions[i]]) === -1){
            flag = false;
        }    
    }
    if (flag){
        return true;
    }
    else{
        return false;
    }
}

export const findClientBySiteId = (siteId) =>{
    let user = JSON.parse(localStorage.getItem('userData'));
    if (user.is_superadmin){
        let clientList = JSON.parse(localStorage.getItem('clientList'));
        let selectedClient = clientList.filter((client)=>{
            return client.site_id === siteId;
        });
        if (selectedClient.length){
            return selectedClient[0]
        }
        else{
            console.log(siteId,clientList);
            API.GET(APIROOT,{remove_pagination:true,role_name:'client'},API.URLS.user,(res)=>{
                if (!!res && !!res.data && res.data.length){
                    localStorage.setItem('clientList',JSON.stringify(res.data));
                    let selectedClient = res.data.filter((client)=>{
                        return client.site_id === siteId
                    });
                    if (selectedClient.length){
                        return selectedClient[0]
                    }
                    else{
                        console.log("Don't Trick Me")
                    }
                }
            },true,true)
        }
    }
}

export const removeSpace = (letter)=>{
    return letter.toString().toLowerCase().replace(/ /g,"-");
}

export const setSiteId = (site_id)=>{
    if(site_id || site_id===0){
        axios.defaults.headers.common['SITE-ID'] = site_id;
    }
    else{
        if (!!localStorage.getItem('SITE-ID')){
            axios.defaults.headers.common['SITE-ID'] = localStorage.getItem('SITE-ID');
        }
        else{
            axios.defaults.headers.common['SITE-ID'] = 0;
        }
    }
}