import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {observer} from 'mobx-react';
import {NotificationManager} from 'react-notifications';

export default observer(class OnlyVerified extends Component {

    render() {
    console.log('auth called')
    const hasToken = localStorage.getItem('Token');
    const userData = localStorage.getItem('userData');
    const is_client = !!userData ? JSON.parse(userData).role_importance === 0:false;
    const paymentSuccess = !!userData ? JSON.parse(userData).package_payment_success:false;
    const is_dashboard = this.props.history.location.pathname.includes('dashboard');
    const is_superadmin = !!userData ? JSON.parse(userData).is_superadmin:false;
    const is_confirmation = this.props.history.location.pathname.includes('confirmation');
    
    const hasAccessToDrawer = (
        !!hasToken && (!(!!is_client) || (is_client && !!paymentSuccess) || is_superadmin)
    );


        if (!(!!hasToken)){
            NotificationManager.error('Please login again.')
            return <Redirect to="/"/>
        }
        else if (hasAccessToDrawer || is_dashboard || is_confirmation) {
            return this.props.children;
        } else {
            NotificationManager.error("You don't have permission to access this feature.");
            NotificationManager.info("if you are Admin make sure your have completed your payment, else please try log out and logging in again");
            return <Redirect to="/dashboard"/>
        }

    }
});