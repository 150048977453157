import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import API, { chatService, chatWs, APIROOT, getUserData, getAuthToken} from '../../src/constants';
import { observable, extendObservable } from 'mobx';
import style from "../views/Chat/new_style";
import {withStyles} from "@material-ui/core/styles/index";
import {observer} from "mobx-react/dist/mobx-react";
import {NotificationManager} from 'react-notifications';

class WorkChat_Notification extends Component{
    constructor(props){
        super(props);
        extendObservable(this, {
            notifications:'',
        });
    }

    componentDidMount(){

        let userData = getUserData();

        let ws = chatWs+API.URLS.notifications+userData.id+'/';

        this.notifications = new WebSocket(ws);

        this.notifications.onopen = () => {
            console.log("CONNECTED LISTENER");
        };

        this.notifications.onmessage = (evt) => {
            const message = JSON.parse(evt.data);
            NotificationManager.info('You have a new chat message');
        };

        this.notifications.onclose = () => {
            console.log("DISCONNECTED LISTENER");
        }
    }

    componentWillUnmount(){
        this.notifications.close();
    }

    render(){
        return null;
    }
}

export default withStyles(style) (observer(WorkChat_Notification));