export let countries = [
  {
    "code": "AF",
    "isocode": "AFG",
    "independent": "Yes",
    "name": {
      "short": "Afghanistan",
      "uppercase": "AFGHANISTAN"
    },
    "numeric": "004",
    "status": "officially-assigned"
  },
  {
    "code": "AL",
    "isocode": "ALB",
    "independent": "Yes",
    "name": {
      "short": "Albania",
      "uppercase": "ALBANIA"
    },
    "numeric": "008",
    "status": "officially-assigned"
  },
  {
    "code": "DZ",
    "isocode": "DZA",
    "independent": "Yes",
    "name": {
      "short": "Algeria",
      "uppercase": "ALGERIA"
    },
    "numeric": "012",
    "status": "officially-assigned"
  },
  {
    "code": "AS",
    "isocode": "ASM",
    "independent": "No",
    "name": {
      "short": "American Samoa",
      "uppercase": "AMERICAN SAMOA"
    },
    "numeric": "016",
    "status": "officially-assigned"
  },
  {
    "code": "AD",
    "isocode": "AND",
    "independent": "Yes",
    "name": {
      "short": "Andorra",
      "uppercase": "ANDORRA"
    },
    "numeric": "020",
    "status": "officially-assigned"
  },
  {
    "code": "AO",
    "isocode": "AGO",
    "independent": "Yes",
    "name": {
      "short": "Angola",
      "uppercase": "ANGOLA"
    },
    "numeric": "024",
    "status": "officially-assigned"
  },
  {
    "code": "AI",
    "isocode": "AIA",
    "independent": "No",
    "name": {
      "short": "Anguilla",
      "uppercase": "ANGUILLA"
    },
    "numeric": "660",
    "status": "officially-assigned"
  },
  {
    "code": "AQ",
    "isocode": "ATA",
    "independent": "No",
    "name": {
      "short": "Antarctica",
      "uppercase": "ANTARCTICA"
    },
    "numeric": "010",
    "status": "officially-assigned"
  },
  {
    "code": "AG",
    "isocode": "ATG",
    "independent": "Yes",
    "name": {
      "short": "Antigua and Barbuda",
      "uppercase": "ANTIGUA AND BARBUDA"
    },
    "numeric": "028",
    "status": "officially-assigned"
  },
  {
    "code": "AR",
    "isocode": "ARG",
    "independent": "Yes",
    "name": {
      "short": "Argentina",
      "uppercase": "ARGENTINA"
    },
    "numeric": "032",
    "status": "officially-assigned"
  },
  {
    "code": "AM",
    "isocode": "ARM",
    "independent": "Yes",
    "name": {
      "short": "Armenia",
      "uppercase": "ARMENIA"
    },
    "numeric": "051",
    "status": "officially-assigned"
  },
  {
    "code": "AW",
    "isocode": "ABW",
    "independent": "No",
    "name": {
      "short": "Aruba",
      "uppercase": "ARUBA"
    },
    "numeric": "533",
    "status": "officially-assigned"
  },
  {
    "code": "AU",
    "isocode": "AUS",
    "independent": "Yes",
    "name": {
      "short": "Australia",
      "uppercase": "AUSTRALIA"
    },
    "numeric": "036",
    "status": "officially-assigned"
  },
  {
    "code": "AT",
    "isocode": "AUT",
    "independent": "Yes",
    "name": {
      "short": "Austria",
      "uppercase": "AUSTRIA"
    },
    "numeric": "040",
    "status": "officially-assigned"
  },
  {
    "code": "AZ",
    "isocode": "AZE",
    "independent": "Yes",
    "name": {
      "short": "Azerbaijan",
      "uppercase": "AZERBAIJAN"
    },
    "numeric": "031",
    "status": "officially-assigned"
  },
  {
    "code": "BS",
    "isocode": "BHS",
    "independent": "Yes",
    "name": {
      "short": "Bahamas (the)",
      "uppercase": "BAHAMAS"
    },
    "numeric": "044",
    "status": "officially-assigned"
  },
  {
    "code": "BH",
    "isocode": "BHR",
    "independent": "Yes",
    "name": {
      "short": "Bahrain",
      "uppercase": "BAHRAIN"
    },
    "numeric": "048",
    "status": "officially-assigned"
  },
  {
    "code": "BD",
    "isocode": "BGD",
    "independent": "Yes",
    "name": {
      "short": "Bangladesh",
      "uppercase": "BANGLADESH"
    },
    "numeric": "050",
    "status": "officially-assigned"
  },
  {
    "code": "BB",
    "isocode": "BRB",
    "independent": "Yes",
    "name": {
      "short": "Barbados",
      "uppercase": "BARBADOS"
    },
    "numeric": "052",
    "status": "officially-assigned"
  },
  {
    "code": "BY",
    "isocode": "BLR",
    "independent": "Yes",
    "name": {
      "short": "Belarus",
      "uppercase": "BELARUS"
    },
    "numeric": "112",
    "status": "officially-assigned"
  },
  {
    "code": "BE",
    "isocode": "BEL",
    "independent": "Yes",
    "name": {
      "short": "Belgium",
      "uppercase": "BELGIUM"
    },
    "numeric": "056",
    "status": "officially-assigned"
  },
  {
    "code": "BZ",
    "isocode": "BLZ",
    "independent": "Yes",
    "name": {
      "short": "Belize",
      "uppercase": "BELIZE"
    },
    "numeric": "084",
    "status": "officially-assigned"
  },
  {
    "code": "BJ",
    "isocode": "BEN",
    "independent": "Yes",
    "name": {
      "short": "Benin",
      "uppercase": "BENIN"
    },
    "numeric": "204",
    "status": "officially-assigned"
  },
  {
    "code": "BM",
    "isocode": "BMU",
    "independent": "No",
    "name": {
      "short": "Bermuda",
      "uppercase": "BERMUDA"
    },
    "numeric": "060",
    "status": "officially-assigned"
  },
  {
    "code": "BT",
    "isocode": "BTN",
    "independent": "Yes",
    "name": {
      "short": "Bhutan",
      "uppercase": "BHUTAN"
    },
    "numeric": "064",
    "status": "officially-assigned"
  },
  {
    "code": "BO",
    "isocode": "BOL",
    "independent": "Yes",
    "name": {
      "short": "Bolivia (Plurinational State of)",
      "uppercase": "BOLIVIA (PLURINATIONAL STATE OF)"
    },
    "numeric": "068",
    "status": "officially-assigned"
  },
  {
    "code": "BQ",
    "isocode": "BES",
    "independent": "No",
    "name": {
      "short": "Bonaire, Sint Eustatius and Saba",
      "uppercase": "BONAIRE, SINT EUSTATIUS AND SABA"
    },
    "numeric": "535",
    "status": "officially-assigned"
  },
  {
    "code": "BA",
    "isocode": "BIH",
    "independent": "Yes",
    "name": {
      "short": "Bosnia and Herzegovina",
      "uppercase": "BOSNIA AND HERZEGOVINA"
    },
    "numeric": "070",
    "status": "officially-assigned"
  },
  {
    "code": "BW",
    "isocode": "BWA",
    "independent": "Yes",
    "name": {
      "short": "Botswana",
      "uppercase": "BOTSWANA"
    },
    "numeric": "072",
    "status": "officially-assigned"
  },
  {
    "code": "BV",
    "isocode": "BVT",
    "independent": "No",
    "name": {
      "short": "Bouvet Island",
      "uppercase": "BOUVET ISLAND"
    },
    "numeric": "074",
    "status": "officially-assigned"
  },
  {
    "code": "BR",
    "isocode": "BRA",
    "independent": "Yes",
    "name": {
      "short": "Brazil",
      "uppercase": "BRAZIL"
    },
    "numeric": "076",
    "status": "officially-assigned"
  },
  {
    "code": "IO",
    "isocode": "IOT",
    "independent": "No",
    "name": {
      "short": "British Indian Ocean Territory (the)",
      "uppercase": "BRITISH INDIAN OCEAN TERRITORY"
    },
    "numeric": "086",
    "status": "officially-assigned"
  },
  {
    "code": "BN",
    "isocode": "BRN",
    "independent": "Yes",
    "name": {
      "short": "Brunei Darussalam",
      "uppercase": "BRUNEI DARUSSALAM"
    },
    "numeric": "096",
    "status": "officially-assigned"
  },
  {
    "code": "BG",
    "isocode": "BGR",
    "independent": "Yes",
    "name": {
      "short": "Bulgaria",
      "uppercase": "BULGARIA"
    },
    "numeric": "100",
    "status": "officially-assigned"
  },
  {
    "code": "BF",
    "isocode": "BFA",
    "independent": "Yes",
    "name": {
      "short": "Burkina Faso",
      "uppercase": "BURKINA FASO"
    },
    "numeric": "854",
    "status": "officially-assigned"
  },
  {
    "code": "BU",
    "isocode": "BUR",
    "independent": "",
    "name": {
      "short": "Burma",
      "uppercase": "Burma"
    },
    "numeric": "104",
    "status": "transitionally-reserved"
  },
  {
    "code": "BI",
    "isocode": "BDI",
    "independent": "Yes",
    "name": {
      "short": "Burundi",
      "uppercase": "BURUNDI"
    },
    "numeric": "108",
    "status": "officially-assigned"
  },
  {
    "code": "KH",
    "isocode": "KHM",
    "independent": "Yes",
    "name": {
      "short": "Cambodia",
      "uppercase": "CAMBODIA"
    },
    "numeric": "116",
    "status": "officially-assigned"
  },
  {
    "code": "CM",
    "isocode": "CMR",
    "independent": "Yes",
    "name": {
      "short": "Cameroon",
      "uppercase": "CAMEROON"
    },
    "numeric": "120",
    "status": "officially-assigned"
  },
  {
    "code": "CA",
    "isocode": "CAN",
    "independent": "Yes",
    "name": {
      "short": "Canada",
      "uppercase": "CANADA"
    },
    "numeric": "124",
    "status": "officially-assigned"
  },
  {
    "code": "CV",
    "isocode": "CPV",
    "independent": "Yes",
    "name": {
      "short": "Cabo Verde",
      "uppercase": "CABO VERDE"
    },
    "numeric": "132",
    "status": "officially-assigned"
  },
  {
    "code": "KY",
    "isocode": "CYM",
    "independent": "No",
    "name": {
      "short": "Cayman Islands (the)",
      "uppercase": "CAYMAN ISLANDS"
    },
    "numeric": "136",
    "status": "officially-assigned"
  },
  {
    "code": "CF",
    "isocode": "CAF",
    "independent": "Yes",
    "name": {
      "short": "Central African Republic (the)",
      "uppercase": "CENTRAL AFRICAN REPUBLIC"
    },
    "numeric": "140",
    "status": "officially-assigned"
  },
  {
    "code": "TD",
    "isocode": "TCD",
    "independent": "Yes",
    "name": {
      "short": "Chad",
      "uppercase": "CHAD"
    },
    "numeric": "148",
    "status": "officially-assigned"
  },
  {
    "code": "CL",
    "isocode": "CHL",
    "independent": "Yes",
    "name": {
      "short": "Chile",
      "uppercase": "CHILE"
    },
    "numeric": "152",
    "status": "officially-assigned"
  },
  {
    "code": "CN",
    "isocode": "CHN",
    "independent": "Yes",
    "name": {
      "short": "China",
      "uppercase": "CHINA"
    },
    "numeric": "156",
    "status": "officially-assigned"
  },
  {
    "code": "CX",
    "isocode": "CXR",
    "independent": "No",
    "name": {
      "short": "Christmas Island",
      "uppercase": "CHRISTMAS ISLAND"
    },
    "numeric": "162",
    "status": "officially-assigned"
  },
  {
    "code": "CC",
    "isocode": "CCK",
    "independent": "No",
    "name": {
      "short": "Cocos (Keeling) Islands (the)",
      "uppercase": "COCOS (KEELING) ISLANDS"
    },
    "numeric": "166",
    "status": "officially-assigned"
  },
  {
    "code": "CO",
    "isocode": "COL",
    "independent": "Yes",
    "name": {
      "short": "Colombia",
      "uppercase": "COLOMBIA"
    },
    "numeric": "170",
    "status": "officially-assigned"
  },
  {
    "code": "KM",
    "isocode": "COM",
    "independent": "Yes",
    "name": {
      "short": "Comoros (the)",
      "uppercase": "COMOROS"
    },
    "numeric": "174",
    "status": "officially-assigned"
  },
  {
    "code": "CG",
    "isocode": "COG",
    "independent": "Yes",
    "name": {
      "short": "Congo (the)",
      "uppercase": "CONGO"
    },
    "numeric": "178",
    "status": "officially-assigned"
  },
  {
    "code": "CD",
    "isocode": "COD",
    "independent": "Yes",
    "name": {
      "short": "Congo (the Democratic Republic of the)",
      "uppercase": "CONGO, DEMOCRATIC REPUBLIC OF THE"
    },
    "numeric": "180",
    "status": "officially-assigned"
  },
  {
    "code": "CK",
    "isocode": "COK",
    "independent": "No",
    "name": {
      "short": "Cook Islands (the)",
      "uppercase": "COOK ISLANDS"
    },
    "numeric": "184",
    "status": "officially-assigned"
  },
  {
    "code": "CR",
    "isocode": "CRI",
    "independent": "Yes",
    "name": {
      "short": "Costa Rica",
      "uppercase": "COSTA RICA"
    },
    "numeric": "188",
    "status": "officially-assigned"
  },
  {
    "code": "HR",
    "isocode": "HRV",
    "independent": "Yes",
    "name": {
      "short": "Croatia",
      "uppercase": "CROATIA"
    },
    "numeric": "191",
    "status": "officially-assigned"
  },
  {
    "code": "CU",
    "isocode": "CUB",
    "independent": "Yes",
    "name": {
      "short": "Cuba",
      "uppercase": "CUBA"
    },
    "numeric": "192",
    "status": "officially-assigned"
  },
  {
    "code": "CW",
    "isocode": "CUW",
    "independent": "No",
    "name": {
      "short": "Curaçao",
      "uppercase": "CURAÇAO"
    },
    "numeric": "531",
    "status": "officially-assigned"
  },
  {
    "code": "CY",
    "isocode": "CYP",
    "independent": "Yes",
    "name": {
      "short": "Cyprus",
      "uppercase": "CYPRUS"
    },
    "numeric": "196",
    "status": "officially-assigned"
  },
  {
    "code": "CZ",
    "isocode": "CZE",
    "independent": "Yes",
    "name": {
      "short": "Czech Republic (the)",
      "uppercase": "CZECH REPUBLIC"
    },
    "numeric": "203",
    "status": "officially-assigned"
  },
  {
    "code": "CI",
    "isocode": "CIV",
    "independent": "Yes",
    "name": {
      "short": "Côte d'Ivoire",
      "uppercase": "CÔTE D'IVOIRE"
    },
    "numeric": "384",
    "status": "officially-assigned"
  },
  {
    "code": "DK",
    "isocode": "DNK",
    "independent": "Yes",
    "name": {
      "short": "Denmark",
      "uppercase": "DENMARK"
    },
    "numeric": "208",
    "status": "officially-assigned"
  },
  {
    "code": "DJ",
    "isocode": "DJI",
    "independent": "Yes",
    "name": {
      "short": "Djibouti",
      "uppercase": "DJIBOUTI"
    },
    "numeric": "262",
    "status": "officially-assigned"
  },
  {
    "code": "DM",
    "isocode": "DMA",
    "independent": "Yes",
    "name": {
      "short": "Dominica",
      "uppercase": "DOMINICA"
    },
    "numeric": "212",
    "status": "officially-assigned"
  },
  {
    "code": "DO",
    "isocode": "DOM",
    "independent": "Yes",
    "name": {
      "short": "Dominican Republic (the)",
      "uppercase": "DOMINICAN REPUBLIC"
    },
    "numeric": "214",
    "status": "officially-assigned"
  },
  {
    "code": "TP",
    "isocode": "TMP",
    "independent": "",
    "name": {
      "short": "East Timor",
      "uppercase": "East Timor"
    },
    "numeric": "626",
    "status": "transitionally-reserved"
  },
  {
    "code": "EC",
    "isocode": "ECU",
    "independent": "Yes",
    "name": {
      "short": "Ecuador",
      "uppercase": "ECUADOR"
    },
    "numeric": "218",
    "status": "officially-assigned"
  },
  {
    "code": "EG",
    "isocode": "EGY",
    "independent": "Yes",
    "name": {
      "short": "Egypt",
      "uppercase": "EGYPT"
    },
    "numeric": "818",
    "status": "officially-assigned"
  },
  {
    "code": "SV",
    "isocode": "SLV",
    "independent": "Yes",
    "name": {
      "short": "El Salvador",
      "uppercase": "EL SALVADOR"
    },
    "numeric": "222",
    "status": "officially-assigned"
  },
  {
    "code": "GQ",
    "isocode": "GNQ",
    "independent": "Yes",
    "name": {
      "short": "Equatorial Guinea",
      "uppercase": "EQUATORIAL GUINEA"
    },
    "numeric": "226",
    "status": "officially-assigned"
  },
  {
    "code": "ER",
    "isocode": "ERI",
    "independent": "Yes",
    "name": {
      "short": "Eritrea",
      "uppercase": "ERITREA"
    },
    "numeric": "232",
    "status": "officially-assigned"
  },
  {
    "code": "EE",
    "isocode": "EST",
    "independent": "Yes",
    "name": {
      "short": "Estonia",
      "uppercase": "ESTONIA"
    },
    "numeric": "233",
    "status": "officially-assigned"
  },
  {
    "code": "ET",
    "isocode": "ETH",
    "independent": "Yes",
    "name": {
      "short": "Ethiopia",
      "uppercase": "ETHIOPIA"
    },
    "numeric": "231",
    "status": "officially-assigned"
  },
  {
    "code": "FK",
    "isocode": "FLK",
    "independent": "No",
    "name": {
      "short": "Falkland Islands (the) [Malvinas]",
      "uppercase": "FALKLAND ISLANDS (MALVINAS)"
    },
    "numeric": "238",
    "status": "officially-assigned"
  },
  {
    "code": "FO",
    "isocode": "FRO",
    "independent": "No",
    "name": {
      "short": "Faroe Islands (the)",
      "uppercase": "FAROE ISLANDS"
    },
    "numeric": "234",
    "status": "officially-assigned"
  },
  {
    "code": "FJ",
    "isocode": "FJI",
    "independent": "Yes",
    "name": {
      "short": "Fiji",
      "uppercase": "FIJI"
    },
    "numeric": "242",
    "status": "officially-assigned"
  },
  {
    "code": "FI",
    "isocode": "FIN",
    "independent": "Yes",
    "name": {
      "short": "Finland",
      "uppercase": "FINLAND"
    },
    "numeric": "246",
    "status": "officially-assigned"
  },
  {
    "code": "FR",
    "isocode": "FRA",
    "independent": "Yes",
    "name": {
      "short": "France",
      "uppercase": "FRANCE"
    },
    "numeric": "250",
    "status": "officially-assigned"
  },
  {
    "code": "GF",
    "isocode": "GUF",
    "independent": "No",
    "name": {
      "short": "French Guiana",
      "uppercase": "FRENCH GUIANA"
    },
    "numeric": "254",
    "status": "officially-assigned"
  },
  {
    "code": "PF",
    "isocode": "PYF",
    "independent": "No",
    "name": {
      "short": "French Polynesia",
      "uppercase": "FRENCH POLYNESIA"
    },
    "numeric": "258",
    "status": "officially-assigned"
  },
  {
    "code": "TF",
    "isocode": "ATF",
    "independent": "No",
    "name": {
      "short": "French Southern Territories (the)",
      "uppercase": "FRENCH SOUTHERN TERRITORIES"
    },
    "numeric": "260",
    "status": "officially-assigned"
  },
  {
    "code": "GA",
    "isocode": "GAB",
    "independent": "Yes",
    "name": {
      "short": "Gabon",
      "uppercase": "GABON"
    },
    "numeric": "266",
    "status": "officially-assigned"
  },
  {
    "code": "GM",
    "isocode": "GMB",
    "independent": "Yes",
    "name": {
      "short": "Gambia (the)",
      "uppercase": "GAMBIA"
    },
    "numeric": "270",
    "status": "officially-assigned"
  },
  {
    "code": "GE",
    "isocode": "GEO",
    "independent": "Yes",
    "name": {
      "short": "Georgia",
      "uppercase": "GEORGIA"
    },
    "numeric": "268",
    "status": "officially-assigned"
  },
  {
    "code": "DE",
    "isocode": "DEU",
    "independent": "Yes",
    "name": {
      "short": "Germany",
      "uppercase": "GERMANY"
    },
    "numeric": "276",
    "status": "officially-assigned"
  },
  {
    "code": "GH",
    "isocode": "GHA",
    "independent": "Yes",
    "name": {
      "short": "Ghana",
      "uppercase": "GHANA"
    },
    "numeric": "288",
    "status": "officially-assigned"
  },
  {
    "code": "GI",
    "isocode": "GIB",
    "independent": "No",
    "name": {
      "short": "Gibraltar",
      "uppercase": "GIBRALTAR"
    },
    "numeric": "292",
    "status": "officially-assigned"
  },
  {
    "code": "GR",
    "isocode": "GRC",
    "independent": "Yes",
    "name": {
      "short": "Greece",
      "uppercase": "GREECE"
    },
    "numeric": "300",
    "status": "officially-assigned"
  },
  {
    "code": "GL",
    "isocode": "GRL",
    "independent": "No",
    "name": {
      "short": "Greenland",
      "uppercase": "GREENLAND"
    },
    "numeric": "304",
    "status": "officially-assigned"
  },
  {
    "code": "GD",
    "isocode": "GRD",
    "independent": "Yes",
    "name": {
      "short": "Grenada",
      "uppercase": "GRENADA"
    },
    "numeric": "308",
    "status": "officially-assigned"
  },
  {
    "code": "GP",
    "isocode": "GLP",
    "independent": "No",
    "name": {
      "short": "Guadeloupe",
      "uppercase": "GUADELOUPE"
    },
    "numeric": "312",
    "status": "officially-assigned"
  },
  {
    "code": "GU",
    "isocode": "GUM",
    "independent": "No",
    "name": {
      "short": "Guam",
      "uppercase": "GUAM"
    },
    "numeric": "316",
    "status": "officially-assigned"
  },
  {
    "code": "GT",
    "isocode": "GTM",
    "independent": "Yes",
    "name": {
      "short": "Guatemala",
      "uppercase": "GUATEMALA"
    },
    "numeric": "320",
    "status": "officially-assigned"
  },
  {
    "code": "GG",
    "isocode": "GGY",
    "independent": "No",
    "name": {
      "short": "Guernsey",
      "uppercase": "GUERNSEY"
    },
    "numeric": "831",
    "status": "officially-assigned"
  },
  {
    "code": "GN",
    "isocode": "GIN",
    "independent": "Yes",
    "name": {
      "short": "Guinea",
      "uppercase": "GUINEA"
    },
    "numeric": "324",
    "status": "officially-assigned"
  },
  {
    "code": "GW",
    "isocode": "GNB",
    "independent": "Yes",
    "name": {
      "short": "Guinea-Bissau",
      "uppercase": "GUINEA-BISSAU"
    },
    "numeric": "624",
    "status": "officially-assigned"
  },
  {
    "code": "GY",
    "isocode": "GUY",
    "independent": "Yes",
    "name": {
      "short": "Guyana",
      "uppercase": "GUYANA"
    },
    "numeric": "328",
    "status": "officially-assigned"
  },
  {
    "code": "HT",
    "isocode": "HTI",
    "independent": "Yes",
    "name": {
      "short": "Haiti",
      "uppercase": "HAITI"
    },
    "numeric": "332",
    "status": "officially-assigned"
  },
  {
    "code": "HM",
    "isocode": "HMD",
    "independent": "No",
    "name": {
      "short": "Heard Island and McDonald Islands",
      "uppercase": "HEARD ISLAND AND MCDONALD ISLANDS"
    },
    "numeric": "334",
    "status": "officially-assigned"
  },
  {
    "code": "VA",
    "isocode": "VAT",
    "independent": "Yes",
    "name": {
      "short": "Holy See (the)",
      "uppercase": "HOLY SEE"
    },
    "numeric": "336",
    "status": "officially-assigned"
  },
  {
    "code": "HN",
    "isocode": "HND",
    "independent": "Yes",
    "name": {
      "short": "Honduras",
      "uppercase": "HONDURAS"
    },
    "numeric": "340",
    "status": "officially-assigned"
  },
  {
    "code": "HK",
    "isocode": "HKG",
    "independent": "No",
    "name": {
      "short": "Hong Kong",
      "uppercase": "HONG KONG"
    },
    "numeric": "344",
    "status": "officially-assigned"
  },
  {
    "code": "HU",
    "isocode": "HUN",
    "independent": "Yes",
    "name": {
      "short": "Hungary",
      "uppercase": "HUNGARY"
    },
    "numeric": "348",
    "status": "officially-assigned"
  },
  {
    "code": "IS",
    "isocode": "ISL",
    "independent": "Yes",
    "name": {
      "short": "Iceland",
      "uppercase": "ICELAND"
    },
    "numeric": "352",
    "status": "officially-assigned"
  },
  {
    "code": "IN",
    "isocode": "IND",
    "independent": "Yes",
    "name": {
      "short": "India",
      "uppercase": "INDIA"
    },
    "numeric": "356",
    "status": "officially-assigned"
  },
  {
    "code": "ID",
    "isocode": "IDN",
    "independent": "Yes",
    "name": {
      "short": "Indonesia",
      "uppercase": "INDONESIA"
    },
    "numeric": "360",
    "status": "officially-assigned"
  },
  {
    "code": "IR",
    "isocode": "IRN",
    "independent": "Yes",
    "name": {
      "short": "Iran (Islamic Republic of)",
      "uppercase": "IRAN (ISLAMIC REPUBLIC OF)"
    },
    "numeric": "364",
    "status": "officially-assigned"
  },
  {
    "code": "IQ",
    "isocode": "IRQ",
    "independent": "Yes",
    "name": {
      "short": "Iraq",
      "uppercase": "IRAQ"
    },
    "numeric": "368",
    "status": "officially-assigned"
  },
  {
    "code": "IE",
    "isocode": "IRL",
    "independent": "Yes",
    "name": {
      "short": "Ireland",
      "uppercase": "IRELAND"
    },
    "numeric": "372",
    "status": "officially-assigned"
  },
  {
    "code": "IM",
    "isocode": "IMN",
    "independent": "No",
    "name": {
      "short": "Isle of Man",
      "uppercase": "ISLE OF MAN"
    },
    "numeric": "833",
    "status": "officially-assigned"
  },
  {
    "code": "IL",
    "isocode": "ISR",
    "independent": "Yes",
    "name": {
      "short": "Israel",
      "uppercase": "ISRAEL"
    },
    "numeric": "376",
    "status": "officially-assigned"
  },
  {
    "code": "IT",
    "isocode": "ITA",
    "independent": "Yes",
    "name": {
      "short": "Italy",
      "uppercase": "ITALY"
    },
    "numeric": "380",
    "status": "officially-assigned"
  },
  {
    "code": "JM",
    "isocode": "JAM",
    "independent": "Yes",
    "name": {
      "short": "Jamaica",
      "uppercase": "JAMAICA"
    },
    "numeric": "388",
    "status": "officially-assigned"
  },
  {
    "code": "JP",
    "isocode": "JPN",
    "independent": "Yes",
    "name": {
      "short": "Japan",
      "uppercase": "JAPAN"
    },
    "numeric": "392",
    "status": "officially-assigned"
  },
  {
    "code": "JE",
    "isocode": "JEY",
    "independent": "No",
    "name": {
      "short": "Jersey",
      "uppercase": "JERSEY"
    },
    "numeric": "832",
    "status": "officially-assigned"
  },
  {
    "code": "JO",
    "isocode": "JOR",
    "independent": "Yes",
    "name": {
      "short": "Jordan",
      "uppercase": "JORDAN"
    },
    "numeric": "400",
    "status": "officially-assigned"
  },
  {
    "code": "KZ",
    "isocode": "KAZ",
    "independent": "Yes",
    "name": {
      "short": "Kazakhstan",
      "uppercase": "KAZAKHSTAN"
    },
    "numeric": "398",
    "status": "officially-assigned"
  },
  {
    "code": "KE",
    "isocode": "KEN",
    "independent": "Yes",
    "name": {
      "short": "Kenya",
      "uppercase": "KENYA"
    },
    "numeric": "404",
    "status": "officially-assigned"
  },
  {
    "code": "KI",
    "isocode": "KIR",
    "independent": "Yes",
    "name": {
      "short": "Kiribati",
      "uppercase": "KIRIBATI"
    },
    "numeric": "296",
    "status": "officially-assigned"
  },
  {
    "code": "KP",
    "isocode": "PRK",
    "independent": "Yes",
    "name": {
      "short": "Korea (the Democratic People's Republic of)",
      "uppercase": "KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)"
    },
    "numeric": "408",
    "status": "officially-assigned"
  },
  {
    "code": "KR",
    "isocode": "KOR",
    "independent": "Yes",
    "name": {
      "short": "Korea (the Republic of)",
      "uppercase": "KOREA, REPUBLIC OF"
    },
    "numeric": "410",
    "status": "officially-assigned"
  },
  {
    "code": "XK",
    "isocode": "XKX",
    "name": {
      "short": "Kosovo",
      "uppercase": "KOSOVO"
    },
    "status": "user-assigned"
  },
  {
    "code": "KW",
    "isocode": "KWT",
    "independent": "Yes",
    "name": {
      "short": "Kuwait",
      "uppercase": "KUWAIT"
    },
    "numeric": "414",
    "status": "officially-assigned"
  },
  {
    "code": "KG",
    "isocode": "KGZ",
    "independent": "Yes",
    "name": {
      "short": "Kyrgyzstan",
      "uppercase": "KYRGYZSTAN"
    },
    "numeric": "417",
    "status": "officially-assigned"
  },
  {
    "code": "LA",
    "isocode": "LAO",
    "independent": "Yes",
    "name": {
      "short": "Lao People's Democratic Republic (the)",
      "uppercase": "LAO PEOPLE'S DEMOCRATIC REPUBLIC"
    },
    "numeric": "418",
    "status": "officially-assigned"
  },
  {
    "code": "LV",
    "isocode": "LVA",
    "independent": "Yes",
    "name": {
      "short": "Latvia",
      "uppercase": "LATVIA"
    },
    "numeric": "428",
    "status": "officially-assigned"
  },
  {
    "code": "LB",
    "isocode": "LBN",
    "independent": "Yes",
    "name": {
      "short": "Lebanon",
      "uppercase": "LEBANON"
    },
    "numeric": "422",
    "status": "officially-assigned"
  },
  {
    "code": "LS",
    "isocode": "LSO",
    "independent": "Yes",
    "name": {
      "short": "Lesotho",
      "uppercase": "LESOTHO"
    },
    "numeric": "426",
    "status": "officially-assigned"
  },
  {
    "code": "LR",
    "isocode": "LBR",
    "independent": "Yes",
    "name": {
      "short": "Liberia",
      "uppercase": "LIBERIA"
    },
    "numeric": "430",
    "status": "officially-assigned"
  },
  {
    "code": "LY",
    "isocode": "LBY",
    "independent": "Yes",
    "name": {
      "short": "Libya",
      "uppercase": "LIBYA"
    },
    "numeric": "434",
    "status": "officially-assigned"
  },
  {
    "code": "LI",
    "isocode": "LIE",
    "independent": "Yes",
    "name": {
      "short": "Liechtenstein",
      "uppercase": "LIECHTENSTEIN"
    },
    "numeric": "438",
    "status": "officially-assigned"
  },
  {
    "code": "LT",
    "isocode": "LTU",
    "independent": "Yes",
    "name": {
      "short": "Lithuania",
      "uppercase": "LITHUANIA"
    },
    "numeric": "440",
    "status": "officially-assigned"
  },
  {
    "code": "LU",
    "isocode": "LUX",
    "independent": "Yes",
    "name": {
      "short": "Luxembourg",
      "uppercase": "LUXEMBOURG"
    },
    "numeric": "442",
    "status": "officially-assigned"
  },
  {
    "code": "MO",
    "isocode": "MAC",
    "independent": "No",
    "name": {
      "short": "Macao",
      "uppercase": "MACAO"
    },
    "numeric": "446",
    "status": "officially-assigned"
  },
  {
    "code": "MK",
    "isocode": "MKD",
    "independent": "Yes",
    "name": {
      "short": "Macedonia (the former Yugoslav Republic of)",
      "uppercase": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"
    },
    "numeric": "807",
    "status": "officially-assigned"
  },
  {
    "code": "MG",
    "isocode": "MDG",
    "independent": "Yes",
    "name": {
      "short": "Madagascar",
      "uppercase": "MADAGASCAR"
    },
    "numeric": "450",
    "status": "officially-assigned"
  },
  {
    "code": "MW",
    "isocode": "MWI",
    "independent": "Yes",
    "name": {
      "short": "Malawi",
      "uppercase": "MALAWI"
    },
    "numeric": "454",
    "status": "officially-assigned"
  },
  {
    "code": "MY",
    "isocode": "MYS",
    "independent": "Yes",
    "name": {
      "short": "Malaysia",
      "uppercase": "MALAYSIA"
    },
    "numeric": "458",
    "status": "officially-assigned"
  },
  {
    "code": "MV",
    "isocode": "MDV",
    "independent": "Yes",
    "name": {
      "short": "Maldives",
      "uppercase": "MALDIVES"
    },
    "numeric": "462",
    "status": "officially-assigned"
  },
  {
    "code": "ML",
    "isocode": "MLI",
    "independent": "Yes",
    "name": {
      "short": "Mali",
      "uppercase": "MALI"
    },
    "numeric": "466",
    "status": "officially-assigned"
  },
  {
    "code": "MT",
    "isocode": "MLT",
    "independent": "Yes",
    "name": {
      "short": "Malta",
      "uppercase": "MALTA"
    },
    "numeric": "470",
    "status": "officially-assigned"
  },
  {
    "code": "MH",
    "isocode": "MHL",
    "independent": "Yes",
    "name": {
      "short": "Marshall Islands (the)",
      "uppercase": "MARSHALL ISLANDS"
    },
    "numeric": "584",
    "status": "officially-assigned"
  },
  {
    "code": "MQ",
    "isocode": "MTQ",
    "independent": "No",
    "name": {
      "short": "Martinique",
      "uppercase": "MARTINIQUE"
    },
    "numeric": "474",
    "status": "officially-assigned"
  },
  {
    "code": "MR",
    "isocode": "MRT",
    "independent": "Yes",
    "name": {
      "short": "Mauritania",
      "uppercase": "MAURITANIA"
    },
    "numeric": "478",
    "status": "officially-assigned"
  },
  {
    "code": "MU",
    "isocode": "MUS",
    "independent": "Yes",
    "name": {
      "short": "Mauritius",
      "uppercase": "MAURITIUS"
    },
    "numeric": "480",
    "status": "officially-assigned"
  },
  {
    "code": "YT",
    "isocode": "MYT",
    "independent": "No",
    "name": {
      "short": "Mayotte",
      "uppercase": "MAYOTTE"
    },
    "numeric": "175",
    "status": "officially-assigned"
  },
  {
    "code": "MX",
    "isocode": "MEX",
    "independent": "Yes",
    "name": {
      "short": "Mexico",
      "uppercase": "MEXICO"
    },
    "numeric": "484",
    "status": "officially-assigned"
  },
  {
    "code": "FM",
    "isocode": "FSM",
    "independent": "Yes",
    "name": {
      "short": "Micronesia (Federated States of)",
      "uppercase": "MICRONESIA (FEDERATED STATES OF)"
    },
    "numeric": "583",
    "status": "officially-assigned"
  },
  {
    "code": "MD",
    "isocode": "MDA",
    "independent": "Yes",
    "name": {
      "short": "Moldova (the Republic of)",
      "uppercase": "MOLDOVA, REPUBLIC OF"
    },
    "numeric": "498",
    "status": "officially-assigned"
  },
  {
    "code": "MC",
    "isocode": "MCO",
    "independent": "Yes",
    "name": {
      "short": "Monaco",
      "uppercase": "MONACO"
    },
    "numeric": "492",
    "status": "officially-assigned"
  },
  {
    "code": "MN",
    "isocode": "MNG",
    "independent": "Yes",
    "name": {
      "short": "Mongolia",
      "uppercase": "MONGOLIA"
    },
    "numeric": "496",
    "status": "officially-assigned"
  },
  {
    "code": "ME",
    "isocode": "MNE",
    "independent": "Yes",
    "name": {
      "short": "Montenegro",
      "uppercase": "MONTENEGRO"
    },
    "numeric": "499",
    "status": "officially-assigned"
  },
  {
    "code": "MS",
    "isocode": "MSR",
    "independent": "No",
    "name": {
      "short": "Montserrat",
      "uppercase": "MONTSERRAT"
    },
    "numeric": "500",
    "status": "officially-assigned"
  },
  {
    "code": "MA",
    "isocode": "MAR",
    "independent": "Yes",
    "name": {
      "short": "Morocco",
      "uppercase": "MOROCCO"
    },
    "numeric": "504",
    "status": "officially-assigned"
  },
  {
    "code": "MZ",
    "isocode": "MOZ",
    "independent": "Yes",
    "name": {
      "short": "Mozambique",
      "uppercase": "MOZAMBIQUE"
    },
    "numeric": "508",
    "status": "officially-assigned"
  },
  {
    "code": "MM",
    "isocode": "MMR",
    "independent": "Yes",
    "name": {
      "short": "Myanmar",
      "uppercase": "MYANMAR"
    },
    "numeric": "104",
    "status": "officially-assigned"
  },
  {
    "code": "NA",
    "isocode": "NAM",
    "independent": "Yes",
    "name": {
      "short": "Namibia",
      "uppercase": "NAMIBIA"
    },
    "numeric": "516",
    "status": "officially-assigned"
  },
  {
    "code": "NR",
    "isocode": "NRU",
    "independent": "Yes",
    "name": {
      "short": "Nauru",
      "uppercase": "NAURU"
    },
    "numeric": "520",
    "status": "officially-assigned"
  },
  {
    "code": "NP",
    "isocode": "NPL",
    "independent": "Yes",
    "name": {
      "short": "Nepal",
      "uppercase": "NEPAL"
    },
    "numeric": "524",
    "status": "officially-assigned"
  },
  {
    "code": "AN",
    "isocode": "ANT",
    "independent": "",
    "name": {
      "short": "Netherlands Antilles",
      "uppercase": "Netherlands Antilles"
    },
    "numeric": "530",
    "status": "transitionally-reserved"
  },
  {
    "code": "NL",
    "isocode": "NLD",
    "independent": "Yes",
    "name": {
      "short": "Netherlands (the)",
      "uppercase": "NETHERLANDS"
    },
    "numeric": "528",
    "status": "officially-assigned"
  },
  {
    "code": "NT",
    "isocode": "NTZ",
    "independent": "",
    "name": {
      "short": "Neutral Zone",
      "uppercase": "Neutral Zone"
    },
    "numeric": "536",
    "status": "transitionally-reserved"
  },
  {
    "code": "NC",
    "isocode": "NCL",
    "independent": "No",
    "name": {
      "short": "New Caledonia",
      "uppercase": "NEW CALEDONIA"
    },
    "numeric": "540",
    "status": "officially-assigned"
  },
  {
    "code": "NZ",
    "isocode": "NZL",
    "independent": "Yes",
    "name": {
      "short": "New Zealand",
      "uppercase": "NEW ZEALAND"
    },
    "numeric": "554",
    "status": "officially-assigned"
  },
  {
    "code": "NI",
    "isocode": "NIC",
    "independent": "Yes",
    "name": {
      "short": "Nicaragua",
      "uppercase": "NICARAGUA"
    },
    "numeric": "558",
    "status": "officially-assigned"
  },
  {
    "code": "NE",
    "isocode": "NER",
    "independent": "Yes",
    "name": {
      "short": "Niger (the)",
      "uppercase": "NIGER"
    },
    "numeric": "562",
    "status": "officially-assigned"
  },
  {
    "code": "NG",
    "isocode": "NGA",
    "independent": "Yes",
    "name": {
      "short": "Nigeria",
      "uppercase": "NIGERIA"
    },
    "numeric": "566",
    "status": "officially-assigned"
  },
  {
    "code": "NU",
    "isocode": "NIU",
    "independent": "No",
    "name": {
      "short": "Niue",
      "uppercase": "NIUE"
    },
    "numeric": "570",
    "status": "officially-assigned"
  },
  {
    "code": "NF",
    "isocode": "NFK",
    "independent": "No",
    "name": {
      "short": "Norfolk Island",
      "uppercase": "NORFOLK ISLAND"
    },
    "numeric": "574",
    "status": "officially-assigned"
  },
  {
    "code": "MP",
    "isocode": "MNP",
    "independent": "No",
    "name": {
      "short": "Northern Mariana Islands (the)",
      "uppercase": "NORTHERN MARIANA ISLANDS"
    },
    "numeric": "580",
    "status": "officially-assigned"
  },
  {
    "code": "NO",
    "isocode": "NOR",
    "independent": "Yes",
    "name": {
      "short": "Norway",
      "uppercase": "NORWAY"
    },
    "numeric": "578",
    "status": "officially-assigned"
  },
  {
    "code": "OM",
    "isocode": "OMN",
    "independent": "Yes",
    "name": {
      "short": "Oman",
      "uppercase": "OMAN"
    },
    "numeric": "512",
    "status": "officially-assigned"
  },
  {
    "code": "PK",
    "isocode": "PAK",
    "independent": "Yes",
    "name": {
      "short": "Pakistan",
      "uppercase": "PAKISTAN"
    },
    "numeric": "586",
    "status": "officially-assigned"
  },
  {
    "code": "PW",
    "isocode": "PLW",
    "independent": "Yes",
    "name": {
      "short": "Palau",
      "uppercase": "PALAU"
    },
    "numeric": "585",
    "status": "officially-assigned"
  },
  {
    "code": "PS",
    "isocode": "PSE",
    "independent": "No",
    "name": {
      "short": "Palestine, State of",
      "uppercase": "PALESTINE, STATE OF"
    },
    "numeric": "275",
    "status": "officially-assigned"
  },
  {
    "code": "PA",
    "isocode": "PAN",
    "independent": "Yes",
    "name": {
      "short": "Panama",
      "uppercase": "PANAMA"
    },
    "numeric": "591",
    "status": "officially-assigned"
  },
  {
    "code": "PG",
    "isocode": "PNG",
    "independent": "Yes",
    "name": {
      "short": "Papua New Guinea",
      "uppercase": "PAPUA NEW GUINEA"
    },
    "numeric": "598",
    "status": "officially-assigned"
  },
  {
    "code": "PY",
    "isocode": "PRY",
    "independent": "Yes",
    "name": {
      "short": "Paraguay",
      "uppercase": "PARAGUAY"
    },
    "numeric": "600",
    "status": "officially-assigned"
  },
  {
    "code": "PE",
    "isocode": "PER",
    "independent": "Yes",
    "name": {
      "short": "Peru",
      "uppercase": "PERU"
    },
    "numeric": "604",
    "status": "officially-assigned"
  },
  {
    "code": "PH",
    "isocode": "PHL",
    "independent": "Yes",
    "name": {
      "short": "Philippines (the)",
      "uppercase": "PHILIPPINES"
    },
    "numeric": "608",
    "status": "officially-assigned"
  },
  {
    "code": "PN",
    "isocode": "PCN",
    "independent": "No",
    "name": {
      "short": "Pitcairn",
      "uppercase": "PITCAIRN"
    },
    "numeric": "612",
    "status": "officially-assigned"
  },
  {
    "code": "PL",
    "isocode": "POL",
    "independent": "Yes",
    "name": {
      "short": "Poland",
      "uppercase": "POLAND"
    },
    "numeric": "616",
    "status": "officially-assigned"
  },
  {
    "code": "PT",
    "isocode": "PRT",
    "independent": "Yes",
    "name": {
      "short": "Portugal",
      "uppercase": "PORTUGAL"
    },
    "numeric": "620",
    "status": "officially-assigned"
  },
  {
    "code": "PR",
    "isocode": "PRI",
    "independent": "No",
    "name": {
      "short": "Puerto Rico",
      "uppercase": "PUERTO RICO"
    },
    "numeric": "630",
    "status": "officially-assigned"
  },
  {
    "code": "QA",
    "isocode": "QAT",
    "independent": "Yes",
    "name": {
      "short": "Qatar",
      "uppercase": "QATAR"
    },
    "numeric": "634",
    "status": "officially-assigned"
  },
  {
    "code": "RO",
    "isocode": "ROU",
    "independent": "Yes",
    "name": {
      "short": "Romania",
      "uppercase": "ROMANIA"
    },
    "numeric": "642",
    "status": "officially-assigned"
  },
  {
    "code": "RU",
    "isocode": "RUS",
    "independent": "Yes",
    "name": {
      "short": "Russian Federation (the)",
      "uppercase": "RUSSIAN FEDERATION"
    },
    "numeric": "643",
    "status": "officially-assigned"
  },
  {
    "code": "RW",
    "isocode": "RWA",
    "independent": "Yes",
    "name": {
      "short": "Rwanda",
      "uppercase": "RWANDA"
    },
    "numeric": "646",
    "status": "officially-assigned"
  },
  {
    "code": "RE",
    "isocode": "REU",
    "independent": "No",
    "name": {
      "short": "Réunion",
      "uppercase": "RÉUNION"
    },
    "numeric": "638",
    "status": "officially-assigned"
  },
  {
    "code": "BL",
    "isocode": "BLM",
    "independent": "No",
    "name": {
      "short": "Saint Barthélemy",
      "uppercase": "SAINT BARTHÉLEMY"
    },
    "numeric": "652",
    "status": "officially-assigned"
  },
  {
    "code": "SH",
    "isocode": "SHN",
    "independent": "No",
    "name": {
      "short": "Saint Helena, Ascension and Tristan da Cunha",
      "uppercase": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"
    },
    "numeric": "654",
    "status": "officially-assigned"
  },
  {
    "code": "KN",
    "isocode": "KNA",
    "independent": "Yes",
    "name": {
      "short": "Saint Kitts and Nevis",
      "uppercase": "SAINT KITTS AND NEVIS"
    },
    "numeric": "659",
    "status": "officially-assigned"
  },
  {
    "code": "LC",
    "isocode": "LCA",
    "independent": "Yes",
    "name": {
      "short": "Saint Lucia",
      "uppercase": "SAINT LUCIA"
    },
    "numeric": "662",
    "status": "officially-assigned"
  },
  {
    "code": "MF",
    "isocode": "MAF",
    "independent": "No",
    "name": {
      "short": "Saint Martin (French part)",
      "uppercase": "SAINT MARTIN (FRENCH PART)"
    },
    "numeric": "663",
    "status": "officially-assigned"
  },
  {
    "code": "PM",
    "isocode": "SPM",
    "independent": "No",
    "name": {
      "short": "Saint Pierre and Miquelon",
      "uppercase": "SAINT PIERRE AND MIQUELON"
    },
    "numeric": "666",
    "status": "officially-assigned"
  },
  {
    "code": "VC",
    "isocode": "VCT",
    "independent": "Yes",
    "name": {
      "short": "Saint Vincent and the Grenadines",
      "uppercase": "SAINT VINCENT AND THE GRENADINES"
    },
    "numeric": "670",
    "status": "officially-assigned"
  },
  {
    "code": "WS",
    "isocode": "WSM",
    "independent": "Yes",
    "name": {
      "short": "Samoa",
      "uppercase": "SAMOA"
    },
    "numeric": "882",
    "status": "officially-assigned"
  },
  {
    "code": "SM",
    "isocode": "SMR",
    "independent": "Yes",
    "name": {
      "short": "San Marino",
      "uppercase": "SAN MARINO"
    },
    "numeric": "674",
    "status": "officially-assigned"
  },
  {
    "code": "ST",
    "isocode": "STP",
    "independent": "Yes",
    "name": {
      "short": "Sao Tome and Principe",
      "uppercase": "SAO TOME AND PRINCIPE"
    },
    "numeric": "678",
    "status": "officially-assigned"
  },
  {
    "code": "SA",
    "isocode": "SAU",
    "independent": "Yes",
    "name": {
      "short": "Saudi Arabia",
      "uppercase": "SAUDI ARABIA"
    },
    "numeric": "682",
    "status": "officially-assigned"
  },
  {
    "code": "SN",
    "isocode": "SEN",
    "independent": "Yes",
    "name": {
      "short": "Senegal",
      "uppercase": "SENEGAL"
    },
    "numeric": "686",
    "status": "officially-assigned"
  },
  {
    "code": "CS",
    "isocode": "SCG",
    "independent": "",
    "name": {
      "short": "Serbia and Montenegro",
      "uppercase": "SERBIA AND MONTENEGRO"
    },
    "numeric": "891",
    "status": "transitionally-reserved"
  },
  {
    "code": "RS",
    "isocode": "SRB",
    "independent": "Yes",
    "name": {
      "short": "Serbia",
      "uppercase": "SERBIA"
    },
    "numeric": "688",
    "status": "officially-assigned"
  },
  {
    "code": "SC",
    "isocode": "SYC",
    "independent": "Yes",
    "name": {
      "short": "Seychelles",
      "uppercase": "SEYCHELLES"
    },
    "numeric": "690",
    "status": "officially-assigned"
  },
  {
    "code": "SL",
    "isocode": "SLE",
    "independent": "Yes",
    "name": {
      "short": "Sierra Leone",
      "uppercase": "SIERRA LEONE"
    },
    "numeric": "694",
    "status": "officially-assigned"
  },
  {
    "code": "SG",
    "isocode": "SGP",
    "independent": "Yes",
    "name": {
      "short": "Singapore",
      "uppercase": "SINGAPORE"
    },
    "numeric": "702",
    "status": "officially-assigned"
  },
  {
    "code": "SX",
    "isocode": "SXM",
    "independent": "No",
    "name": {
      "short": "Sint Maarten (Dutch part)",
      "uppercase": "SINT MAARTEN (DUTCH PART)"
    },
    "numeric": "534",
    "status": "officially-assigned"
  },
  {
    "code": "SK",
    "isocode": "SVK",
    "independent": "Yes",
    "name": {
      "short": "Slovakia",
      "uppercase": "SLOVAKIA"
    },
    "numeric": "703",
    "status": "officially-assigned"
  },
  {
    "code": "SI",
    "isocode": "SVN",
    "independent": "Yes",
    "name": {
      "short": "Slovenia",
      "uppercase": "SLOVENIA"
    },
    "numeric": "705",
    "status": "officially-assigned"
  },
  {
    "code": "SB",
    "isocode": "SLB",
    "independent": "Yes",
    "name": {
      "short": "Solomon Islands",
      "uppercase": "SOLOMON ISLANDS"
    },
    "numeric": "090",
    "status": "officially-assigned"
  },
  {
    "code": "SO",
    "isocode": "SOM",
    "independent": "Yes",
    "name": {
      "short": "Somalia",
      "uppercase": "SOMALIA"
    },
    "numeric": "706",
    "status": "officially-assigned"
  },
  {
    "code": "ZA",
    "isocode": "ZAF",
    "independent": "Yes",
    "name": {
      "short": "South Africa",
      "uppercase": "SOUTH AFRICA"
    },
    "numeric": "710",
    "status": "officially-assigned"
  },
  {
    "code": "GS",
    "isocode": "SGS",
    "independent": "No",
    "name": {
      "short": "South Georgia and the South Sandwich Islands",
      "uppercase": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"
    },
    "numeric": "239",
    "status": "officially-assigned"
  },
  {
    "code": "SS",
    "isocode": "SSD",
    "independent": "Yes",
    "name": {
      "short": "South Sudan",
      "uppercase": "SOUTH SUDAN"
    },
    "numeric": "728",
    "status": "officially-assigned"
  },
  {
    "code": "ES",
    "isocode": "ESP",
    "independent": "Yes",
    "name": {
      "short": "Spain",
      "uppercase": "SPAIN"
    },
    "numeric": "724",
    "status": "officially-assigned"
  },
  {
    "code": "LK",
    "isocode": "LKA",
    "independent": "Yes",
    "name": {
      "short": "Sri Lanka",
      "uppercase": "SRI LANKA"
    },
    "numeric": "144",
    "status": "officially-assigned"
  },
  {
    "code": "SD",
    "isocode": "SDN",
    "independent": "Yes",
    "name": {
      "short": "Sudan (the)",
      "uppercase": "SUDAN"
    },
    "numeric": "729",
    "status": "officially-assigned"
  },
  {
    "code": "SR",
    "isocode": "SUR",
    "independent": "Yes",
    "name": {
      "short": "Suriname",
      "uppercase": "SURINAME"
    },
    "numeric": "740",
    "status": "officially-assigned"
  },
  {
    "code": "SJ",
    "isocode": "SJM",
    "independent": "No",
    "name": {
      "short": "Svalbard and Jan Mayen",
      "uppercase": "SVALBARD AND JAN MAYEN"
    },
    "numeric": "744",
    "status": "officially-assigned"
  },
  {
    "code": "SZ",
    "isocode": "SWZ",
    "independent": "Yes",
    "name": {
      "short": "Swaziland",
      "uppercase": "SWAZILAND"
    },
    "numeric": "748",
    "status": "officially-assigned"
  },
  {
    "code": "SE",
    "isocode": "SWE",
    "independent": "Yes",
    "name": {
      "short": "Sweden",
      "uppercase": "SWEDEN"
    },
    "numeric": "752",
    "status": "officially-assigned"
  },
  {
    "code": "CH",
    "isocode": "CHE",
    "independent": "Yes",
    "name": {
      "short": "Switzerland",
      "uppercase": "SWITZERLAND"
    },
    "numeric": "756",
    "status": "officially-assigned"
  },
  {
    "code": "SY",
    "isocode": "SYR",
    "independent": "Yes",
    "name": {
      "short": "Syrian Arab Republic",
      "uppercase": "SYRIAN ARAB REPUBLIC"
    },
    "numeric": "760",
    "status": "officially-assigned"
  },
  {
    "code": "TW",
    "isocode": "TWN",
    "independent": "No",
    "name": {
      "short": "Taiwan (Province of China)",
      "uppercase": "TAIWAN, PROVINCE OF CHINA"
    },
    "numeric": "158",
    "status": "officially-assigned"
  },
  {
    "code": "TJ",
    "isocode": "TJK",
    "independent": "Yes",
    "name": {
      "short": "Tajikistan",
      "uppercase": "TAJIKISTAN"
    },
    "numeric": "762",
    "status": "officially-assigned"
  },
  {
    "code": "TZ",
    "isocode": "TZA",
    "independent": "Yes",
    "name": {
      "short": "Tanzania, United Republic of",
      "uppercase": "TANZANIA, UNITED REPUBLIC OF"
    },
    "numeric": "834",
    "status": "officially-assigned"
  },
  {
    "code": "TH",
    "isocode": "THA",
    "independent": "Yes",
    "name": {
      "short": "Thailand",
      "uppercase": "THAILAND"
    },
    "numeric": "764",
    "status": "officially-assigned"
  },
  {
    "code": "TL",
    "isocode": "TLS",
    "independent": "Yes",
    "name": {
      "short": "Timor-Leste",
      "uppercase": "TIMOR-LESTE"
    },
    "numeric": "626",
    "status": "officially-assigned"
  },
  {
    "code": "TG",
    "isocode": "TGO",
    "independent": "Yes",
    "name": {
      "short": "Togo",
      "uppercase": "TOGO"
    },
    "numeric": "768",
    "status": "officially-assigned"
  },
  {
    "code": "TK",
    "isocode": "TKL",
    "independent": "No",
    "name": {
      "short": "Tokelau",
      "uppercase": "TOKELAU"
    },
    "numeric": "772",
    "status": "officially-assigned"
  },
  {
    "code": "TO",
    "isocode": "TON",
    "independent": "Yes",
    "name": {
      "short": "Tonga",
      "uppercase": "TONGA"
    },
    "numeric": "776",
    "status": "officially-assigned"
  },
  {
    "code": "TT",
    "isocode": "TTO",
    "independent": "Yes",
    "name": {
      "short": "Trinidad and Tobago",
      "uppercase": "TRINIDAD AND TOBAGO"
    },
    "numeric": "780",
    "status": "officially-assigned"
  },
  {
    "code": "TN",
    "isocode": "TUN",
    "independent": "Yes",
    "name": {
      "short": "Tunisia",
      "uppercase": "TUNISIA"
    },
    "numeric": "788",
    "status": "officially-assigned"
  },
  {
    "code": "TR",
    "isocode": "TUR",
    "independent": "Yes",
    "name": {
      "short": "Turkey",
      "uppercase": "TURKEY"
    },
    "numeric": "792",
    "status": "officially-assigned"
  },
  {
    "code": "TM",
    "isocode": "TKM",
    "independent": "Yes",
    "name": {
      "short": "Turkmenistan",
      "uppercase": "TURKMENISTAN"
    },
    "numeric": "795",
    "status": "officially-assigned"
  },
  {
    "code": "TC",
    "isocode": "TCA",
    "independent": "No",
    "name": {
      "short": "Turks and Caicos Islands (the)",
      "uppercase": "TURKS AND CAICOS ISLANDS"
    },
    "numeric": "796",
    "status": "officially-assigned"
  },
  {
    "code": "TV",
    "isocode": "TUV",
    "independent": "Yes",
    "name": {
      "short": "Tuvalu",
      "uppercase": "TUVALU"
    },
    "numeric": "798",
    "status": "officially-assigned"
  },
  {
    "code": "UG",
    "isocode": "UGA",
    "independent": "Yes",
    "name": {
      "short": "Uganda",
      "uppercase": "UGANDA"
    },
    "numeric": "800",
    "status": "officially-assigned"
  },
  {
    "code": "UA",
    "isocode": "UKR",
    "independent": "Yes",
    "name": {
      "short": "Ukraine",
      "uppercase": "UKRAINE"
    },
    "numeric": "804",
    "status": "officially-assigned"
  },
  {
    "code": "AE",
    "isocode": "ARE",
    "independent": "Yes",
    "name": {
      "short": "United Arab Emirates (the)",
      "uppercase": "UNITED ARAB EMIRATES"
    },
    "numeric": "784",
    "status": "officially-assigned"
  },
  {
    "code": "GB",
    "isocode": "GBR",
    "independent": "Yes",
    "name": {
      "short": "United Kingdom of Great Britain and Northern Ireland (the)",
      "uppercase": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN  IRELAND"
    },
    "numeric": "826",
    "status": "officially-assigned"
  },
  {
    "code": "US",
    "isocode": "USA",
    "independent": "Yes",
    "name": {
      "short": "United States of America (the)",
      "uppercase": "UNITED STATES OF AMERICA"
    },
    "numeric": "840",
    "status": "officially-assigned"
  },
  {
    "code": "UM",
    "isocode": "UMI",
    "independent": "No",
    "name": {
      "short": "United States Minor Outlying Islands (the)",
      "uppercase": "UNITED STATES MINOR OUTLYING ISLANDS"
    },
    "numeric": "581",
    "status": "officially-assigned"
  },
  {
    "code": "UY",
    "isocode": "URY",
    "independent": "Yes",
    "name": {
      "short": "Uruguay",
      "uppercase": "URUGUAY"
    },
    "numeric": "858",
    "status": "officially-assigned"
  },
  {
    "code": "UZ",
    "isocode": "UZB",
    "independent": "Yes",
    "name": {
      "short": "Uzbekistan",
      "uppercase": "UZBEKISTAN"
    },
    "numeric": "860",
    "status": "officially-assigned"
  },
  {
    "code": "VU",
    "isocode": "VUT",
    "independent": "Yes",
    "name": {
      "short": "Vanuatu",
      "uppercase": "VANUATU"
    },
    "numeric": "548",
    "status": "officially-assigned"
  },
  {
    "code": "VE",
    "isocode": "VEN",
    "independent": "Yes",
    "name": {
      "short": "Venezuela (Bolivarian Republic of)",
      "uppercase": "VENEZUELA (BOLIVARIAN REPUBLIC OF)"
    },
    "numeric": "862",
    "status": "officially-assigned"
  },
  {
    "code": "VN",
    "isocode": "VNM",
    "independent": "Yes",
    "name": {
      "short": "Viet Nam",
      "uppercase": "VIET NAM"
    },
    "numeric": "704",
    "status": "officially-assigned"
  },
  {
    "code": "VG",
    "isocode": "VGB",
    "independent": "No",
    "name": {
      "short": "Virgin Islands (British)",
      "uppercase": "VIRGIN ISLANDS (BRITISH)"
    },
    "numeric": "092",
    "status": "officially-assigned"
  },
  {
    "code": "VI",
    "isocode": "VIR",
    "independent": "No",
    "name": {
      "short": "Virgin Islands (U.S.)",
      "uppercase": "VIRGIN ISLANDS (U.S.)"
    },
    "numeric": "850",
    "status": "officially-assigned"
  },
  {
    "code": "WF",
    "isocode": "WLF",
    "independent": "No",
    "name": {
      "short": "Wallis and Futuna",
      "uppercase": "WALLIS AND FUTUNA"
    },
    "numeric": "876",
    "status": "officially-assigned"
  },
  {
    "code": "EH",
    "isocode": "ESH",
    "independent": "No",
    "name": {
      "short": "Western Sahara*",
      "uppercase": "WESTERN SAHARA *"
    },
    "numeric": "732",
    "status": "officially-assigned"
  },
  {
    "code": "YE",
    "isocode": "YEM",
    "independent": "Yes",
    "name": {
      "short": "Yemen",
      "uppercase": "YEMEN"
    },
    "numeric": "887",
    "status": "officially-assigned"
  },
  {
    "code": "YU",
    "isocode": "YUG",
    "independent": "",
    "name": {
      "short": "Yugoslavia",
      "uppercase": "Yugoslavia"
    },
    "numeric": "891",
    "status": "transitionally-reserved"
  },
  {
    "code": "ZR",
    "isocode": "ZAR",
    "independent": "",
    "name": {
      "short": "Zaire",
      "uppercase": "Zaire"
    },
    "numeric": "180",
    "status": "transitionally-reserved"
  },
  {
    "code": "ZM",
    "isocode": "ZMB",
    "independent": "Yes",
    "name": {
      "short": "Zambia",
      "uppercase": "ZAMBIA"
    },
    "numeric": "894",
    "status": "officially-assigned"
  },
  {
    "code": "ZW",
    "isocode": "ZWE",
    "independent": "Yes",
    "name": {
      "short": "Zimbabwe",
      "uppercase": "ZIMBABWE"
    },
    "numeric": "716",
    "status": "officially-assigned"
  },
  {
    "code": "AX",
    "isocode": "ALA",
    "independent": "No",
    "name": {
      "short": "Åland Islands",
      "uppercase": "ÅLAND ISLANDS"
    },
    "numeric": "248",
    "status": "officially-assigned"
  }
];