export const available_permission = {
  can_act_on_shift_request: "can_act_on_shift_request",
  can_act_on_time_off_request: "can_act_on_time_off_request",
  can_chat: "can_chat",
  can_create_accounts: "can_create_accounts",
  can_create_brand: "can_create_brand",
  can_create_company: "can_create_company",
  can_create_features: "can_create_features",
  can_create_geographical_area: "can_create_geographical_area",
  can_create_leave: "can_create_leave",
  can_create_packages: "can_create_packages",
  can_create_poi: "can_create_poi",
  can_create_role: "can_create_role",
  can_create_shift: "can_create_shift",
  can_create_shift_request: "can_create_shift_request",
  can_create_team: "can_create_team",
  can_create_time_off_request: "can_create_time_off_request",
  can_create_user: "can_create_user",
  can_deactivate_accounts: "can_deactivate_accounts",
  can_deactivate_brand: "can_deactivate_brand",
  can_deactivate_company: "can_deactivate_company",
  can_deactivate_features: "can_deactivate_features",
  can_deactivate_geographical_area: "can_deactivate_geographical_area",
  can_deactivate_leave: "can_deactivate_leave",
  can_deactivate_packages: "can_deactivate_packages",
  can_deactivate_poi: "can_deactivate_poi",
  can_deactivate_role: "can_deactivate_role",
  can_deactivate_shift: "can_deactivate_shift",
  can_deactivate_team: "can_deactivate_team",
  can_deactivate_time_off_request: "can_deactivate_time_off_request",
  can_deactivate_user: "can_deactivate_user",
  can_edit_attendance: "can_edit_attendance",
  can_edit_leave: "can_edit_leave",
  can_edit_role: "can_edit_role",
  can_edit_shift: "can_edit_shift",
  can_edit_team: "can_edit_team",
  can_edit_time_off_request: "can_edit_time_off_request",
  can_edit_user: "can_edit_user",
  can_update_accounts: "can_update_accounts",
  can_update_brand: "can_update_brand",
  can_update_company: "can_update_company",
  can_update_features: "can_update_features",
  can_update_geographical_area: "can_update_geographical_area",
  can_update_packages: "can_update_packages",
  can_update_poi: "can_update_poi",
  can_view_accounts: "can_view_accounts",
  can_view_attendance: "can_view_attendance",
  can_view_brand: "can_view_brand",
  can_view_company: "can_view_company",
  can_view_geographical_area: "can_view_geographical_area",
  can_view_leave: "can_view_leave",
  can_view_permission: "can_view_permission",
  can_view_poi: "can_view_poi",
  can_view_role: "can_view_role",
  can_view_shift: "can_view_shift",
  can_view_shift_request: "can_view_shift_request",
  can_view_team: "can_view_team",
  can_view_time_off_request: "can_view_time_off_request",
  can_view_user: "can_view_user"
}