import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {setSiteId} from './helper';

// console.log(!!localStorage.getItem('SITE-ID'),localStorage.getItem('SITE-ID'));

setSiteId();

if (window.location.hostname !=='localhost'){
    console.log = function(){}
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
