const style = {
    sideList:{
        backgroundColor:'#fcfcfc',
        width:'97%',
        paddingLeft:'3%',
        // height:'89vh',
        height:'calc(100vh - 74px)',
        // overflow:'scroll'
    },
    recentChatTitle:{
        fontSize:22,
        color:'#676767',
        fontFamily:'Noto Sans',
    },
    recentDropdown:{
        height:34,
        minWidth:35,
        marginLeft:16,
        borderRadius:3,
        border:'solid 1px #cccccc'
    },
    searchBox:{
        height:'35px',
        margin: '25px 28px 30px 28px',
        border: 'solid 1px #9b9b9b',
        borderRadius:3,
        fontSize:14,
        fontFamily:'Noto Sans',
        opacity:0.6,
    },
    searchInput: {
        outline: 'none',
        border: 0,
        height: 33,
        marginLeft:10,
        width:'75%'
    },
    icon: {
        float: 'right',
        height: 30,
        marginRight:10,
        marginTop:2,
    },
    sideListCards:{
        display:'flex',
        flexDirection:'row',
        backgroundColor:'#fff',
        width:'325px',
        height:85,
        borderRadius:8,
        margin:'15px 0px 15px 30px',
        boxShadow:'0 0 9px 0 rgba(212, 212, 212, 0.5)',
        cursor:'pointer'
    },
    sideListCardsSelected:{
        display:'flex',
        flexDirection:'row',
        background:'linear-gradient(90deg, #27a5df 2%, #FFF 0)',
        width:'325px',
        height:85,
        borderRadius:8,
        margin:'15px 0px 15px 30px',
        boxShadow:'0 0 9px 0 rgba(212, 212, 212, 0.5)',
        cursor:'pointer'
    },
    avatar:{
        height:54, width:54, borderRadius:27, margin:16
    },
    sideListHeading:{
        width:155,
        margin:'23px 0px 0px 0px',
        color:'#282828',
        fontSize:14,
        fontFamily:'Noto Sans',
        fontWeight:600,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap'
    },
    sideListSubheading:{
        color:'#a0a0a0',
        whiteSpace:'nowrap',
        fontSize:14,
        overflow:'hidden',
        textOverflow:'ellipsis',
        fontFamily:'Noto Sans',
    },
    timeStamp:{
        color:'#929292',
        fontSize:12,
        fontFamily:'Noto Sans',
        margin:'24px 0px 19px 30px',
    },
    notificationBadge:{
        width:23, height:23,
        backgroundColor:'#27a5df', fontSize:12,
        color:'#fff', fontFamily:'Noto Sans',
        borderRadius:11, display:'block',
        textAlign:'center', lineHeight:2,
        marginLeft:4
    },

    // messageWindow:{
    //     position: 'relative',
    //     background: '#00aabb',
    //     height:100,
    //     borderRadius:'.4em',
    //     '&:after':{
    //         content:"''",
    //         position:"absolute",
    //         right:"0",
    //         top:"50%",
    //         width:"0",
    //         height:"0",
    //         border:"17px solid transparent",
    //         borderLeftColor:"#00aabb",
    //         borderRight:"0",
    //         marginTop:"-17px",
    //         marginRight:"-17px"
    //     }
    // },
    headerRoot: {
        display:'flex',
        flexDirection:'row',
        position:'relative',
    },
    heading: {
        fontSize:32,
        fontFamily:'Noto Sans',
        paddingTop:32,
        paddingLeft:30,
        paddingBottom:50
    },
    refreshBtn:{
        border: 'solid 1px #27abe9',
        borderRadius:'3px',
        width:105,
        height:30,
        top:32,
        position:'absolute',
        right:20,
        color:'#27abe9',
        fontSize:13,
        fontFamily:'Noto Sans',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
        padding:0
    },

    header:{
        height:130,
        // width:882,
        // width:'63%',
        backgroundColor:'#fff',
        display:'flex',
        // flexDirection:'row',
        // top: 110,
        // position: 'absolute',
        // overflow: 'hidden',
    },
    headerProfilePic:{
        height:72, width:72,
        borderRadius:36,
        margin:'41px 0px 17px 49px'
    },
    headerText: {
        fontFamily:'Noto Sans',
        fontSize:14,
        color:'#282828',
        fontWeight:600,
        margin:'50px 0px 0px 20px',
    },
    subHeader:{
        fontFamily:'Noto Sans',
        fontSize:14,
        color:'#a0a0a0',
        width:150,
    },

    subHeaderInChat:{
        fontFamily:'Noto Sans',
        fontSize:14,
        color:'#a0a0a0'
    },
    addUserBtn:{
        minWidth:30, height:40, margin:'50px 50px', float:'right'
    },


    chatBody:{
        // height:'65vh',
        height:'calc(100vh - 308px)',
        // backgroundColor:'#fff',
        // top: 240,
        // overflow: 'auto',
        // position: 'absolute',
        // width: '63%'
        // width: '100%'
    },
    topDate:{
        marginTop:25,
    },
    image:{
        height:40, width:40, borderRadius:20, margin:10,
    },
    messageBody:{
        maxWidth:400,
        color:'#676767',
        fontSize:13,
        fontFamily:'Noto Sans',
        backgroundColor:'#f9f8f8',
        borderRadius:9,
    },
    senderBubble:{
        margin:10,
        width:'auto',
        padding:10,
        height:'fit-content',
        overflowWrap:'break-word'
    },
    chatBoxMargin:{
        paddingLeft:45,
        paddingRight:45,
    },
    timeUnderPressure:{
        textAlign:'center',
        fontSize:10,
        color:'#a0a0a0',
        fontFamily:'Noto Sans'
    },
    chatBoxFooter:{
        // backgroundColor:'#fff',
        // overflow: 'hidden',
        // position: 'absolute',
        // position: 'fixed',
        // width: '60%',
        // marginTop:53,
        // bottom:0,
        height:'108px',
        display:'flex',
        alignItems:'center'
    },
    inputTextChat:{
        border:0,
        // width:432,
        width:410,
        height:53,
        outline:'none',
        '&:placeholder':{
            color:'#676767',
            fontSize:13,
            fontFamily:'Noto Sans'
        }
    },
    sendBtn:{

    },





    msgTitle:{
        height: 33,
        fontFamily: 'Noto Sans',
        fontSize: 24,
        fontWeight: 600,
        width:'100%',
        color: '#333333',
        margin:'22px 0 0 29px'
    },
    multiSelectFormControl: {
        margin: '33px 33px 0 30px', minWidth: 217,

    },
    selectTextField: {
        minHeight:42
    },
    selectMenuClass:{
        padding:10,
        paddingLeft:15,
        fontSize:15
    },
    multitextField: {
        margin:'30px 33px 0 31px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        height:19,
        borderRadius:3
    },
    button: {
        width:122,
        height:45,
        borderRadius:3,
        margin:5,
        marginTop:30,
        color:'#fff',
        fontSize:14,
        fontFamily:'Noto Sans',
        '&:hover': {
            backgroundColor:''
        }
    },
    switchBtn: {
        height:45,
        borderRadius:3,
        margin:5,
        // color:'#fff',
        // backgroundColor:'#27abe9',
        fontSize:14,
        fontFamily:'Noto Sans',
        '&:hover': {
            backgroundColor:''
        }
    },
    selectSwitchBtn:{
        color:'#fff',
        backgroundColor:'#27abe9',
    },
    unselectSwitchBtn:{
        color:'#fff',
        backgroundColor:'#97979b',
    },
    send:{
        backgroundColor:'#27abe9',
        marginRight:0,
        '&:hover': {
            backgroundColor:'#2fc4e9'
        },
    },
    cancel:{
        backgroundColor:'#363838',
        '&:hover': {
            backgroundColor:'#5c5e5e'
        },
    },

    //Popovers
    popOverPaper:{
        width:338,
        overflow:'scroll',
        height:530,
        borderRadius:7,
        boxShadow:'0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 4px 9px 0 rgba(0, 0, 0, 0.2)',
        padding:10,
    },

    listItemChat:{
        color:'#28a6e1',
        fontSize:13,
        fontFamily:'Noto Sans',
        textAlign:'end',
    }





}

export default style;