const styles = theme => ({
    formContainer:{
        '& *':{
            fontFamily:'Noto Sans !important'
        },
        '& .Mui-error':{
            marginLeft:0
        }
    },
    headerText: {
        fontSize:24,
        fontFamily:'Noto Sans',
        color:'#333333',
        height:35,
        fontWeight:600,
        textTransform: 'uppercase',
        margin:'30px 0px 40px 0px',
    },
    saveBtn: {
        backgroundColor:'#27abe9',
        fontFamily:'Noto Sans',
        width:122,
        clear:'both',
        height:45,
        fontSize:14,
        color:'#fff',
        fontWeight:600,
        float:'right',
        margin:'30px 36px 0px 0px',
        '&:hover' : {
            backgroundColor: '#4798d2',
        }
    },
    labelName:{
        fontFamily:'Noto Sans',
        fontWeight:700,
        color: '#929292',
        fontSize: '14px'
    },
    labelValue:{
        color: '#282828',
        fontFamily:'Noto Sans',
        fontSize: '14px'
    },
    profilePic:{
        maxWidth:'200px',
        margin: '15px 5px 5px',
        padding: '5px',
        border: '3px solid #d6d6d6',
        borderRadius: '3px',
        width: '100%'
    },
    daysLeft:{
        color: '#ff2e2e',
        fontFamily: 'Noto Sans',
        fontSize: '20px',
        fontWeight: '700',
        marginTop: '52px',
        float: 'right',
        paddingRight: '32px',
    },
    addPhoto:{
        textAlign: 'center',
        background: '#f4f4f4',
        color: '#242424',
        fontFamily: 'Poppins',
        fontSize: '16px',
        padding: '5px',
        margin: '0 5px',
        width: '100%'
    },
    spacing: {
        marginBottom: 36
    },
    actionButton:{
        display: 'flex',
    },
    grayBtn: {
        margin:'30px 20px 0px 0px',
        background: '#d6d6d6',
        height:45,
        padding: '10px 20px'
    },
    subHeaderText: {
        fontSize:13,
        fontFamily:'Noto Sans',
        color:'#000000',
        textTransform: 'uppercase',
        paddingRight:'10px',
        background: '#ffffff',
        position: 'relative',
        zIndex: 9
    },
    currentPackage:{
        borderRadius: '8px',
        boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff'
    },
    textField: {
        width:240,
        height:45,
        margin:4,
        marginBottom:35,
        '& input':{
            padding: '14px 9px 12px 18px',
            fontSize: '12px',
            height: '19px'
        }
    },
    selectFormControl: {
        width:240,
        margin:4,
        marginBottom:35,
        '& label': {
            transform: 'translate(14px, 16px) scale(1)',
            fontSize:12,
            color:'#282828',

        },
        '& label[data-shrink="true"]':{
            fontSize:15,
            transform:"translate(17px, -2px) scale(0.75)"
        }

    },
    selectMenuClass:{
        padding: "14px 10px 12px 10px",
        paddingLeft:15,
        fontSize:12,
        height: 19,
    },
    address: {
        width: '100%',
        padding:'0 4px'
    },
    billingSpacing:{
        marginBottom:"20px !important",
    },
    selectTextField: {
        minHeight:42
    },
    multiSelectFormControl: {
        margin: 4, minWidth: 240,
        marginBottom:36
    },

    textFieldRoot: {
        '& label': {
            transform: 'translate(14px, 16px) scale(1)',
            fontSize:12,
            color:'#282828',

        },
        '& label[data-shrink="true"]':{
            fontSize:15,
        },
        '& textarea':{
            fontSize:12
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
            fontSize:15,
            color:'#282828',
        }
    },

    selectTextFieldRoot: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px',
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 12px) scale(1)',
            color:'#282828',
            fontSize:15,
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
            fontSize:15,
            color:'#282828',
        }

    },
    avatar: {
        margin:'38px 0 39px 0', height:100, width:100
    },
    gridMarginforLayout:{
        marginRight:32,
    },
    enableWorkchat: {
        fontFamily:'Noto Sans',
        fontSize:15,
        color:'#707070',
        // margin:'40px 0px 40px -11px'
    },
    divider: {
        width:'100%',
        height:'1px',
        background:'#d8d8d8',
        opacity:0.5,
        marginTop:-17,
    },
    timePicker:{
        width: 240,
        marginTop:39,

        '& .MuiOutlinedInput-adornedEnd-685': {
            paddingRight:0,
        },
        '& .MuiOutlinedInput-root-679':{
            height:45
        }

    },
    addLogo: {
        fontSize:14,
        color:'#27abe9',
        fontFamily:'NotoSans',
        fontWeight:600,
        marginTop:6
    },
    Accntcard:{
        borderRadius: '8px',
        boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.1)',
        padding: '37px 14px',
        lineHeight: '1.86',
        margin: '20px 7px',
        fontSize: '0.9rem',
        fontFamily: "Noto Sans, sans-serif",
        overflow:'hidden',
        // backgroundImage:'url('+cardBG+')',
        // backgroundPosition: '100% 100%',
        display:'inline-block',
        width:'250px'
    },
    accntCardContainer:{
        width:'83%',
        margin:'50px auto',
        display:'flex',
        flexWrap:'wrap'
    },
    accntBtnCardPts:{
        marginTop:'30px'
    },
    accntPkgPt:{
        '& img':{
            margin:'0 9px'
        }
    },
    cardShortInfo:{
        textTransform:'uppercase',
        marginBottom:'50px',
        '& p':{
            textAlign:'center',
            fontFamily:'Noto Sans, sans-serif'
        }
    },
    pakgName:{
        fontSize:'14px'
    },
    usrCount:{
        fontSize:'12px'
    },
    amount:{
        fontSize:'30px',
        fontWeight:700,
        padding:'10px 5px 20px',
        textTransform:'uppercase'
    },
    changeButton:{
        borderRadius: '3px',
        border: 'solid 1px #27abe9',
        color: '#27abe9',
        fontWeight: 700,
        width: '100%',
        marginBottom:10,
    },
    renewButton:{
        borderRadius: '3px',
        border: 'solid 1px #27abe9',
        color: '#ffffff',
        fontWeight: 700,
        width: '100%',
        background:'#27abe9',
        '&:hover':{
            background: '#27abe9'
        }
    },
    bottomImg:{
        position: 'relative',
        width: '100%',
        height: '50px',
        '& img':{
            position: 'absolute',
            left: '-20px',
            right: '-17px',
            width: '123%',
            bottom: '-39px'
        }
    },
    currency:{
        fontSize:'11px'
    },
    flagAdornedStart:{
        paddingLeft:'7px'
    },
    flagRoot:{

    },
    flagPositionStart:{
        height:'100%',
        '& .flag-select':{
            padding:0
        },
        '& .flag-select__btn':{
            overflow: 'hidden',
            height: '100%',
            padding: 0,
            '& button':{
                padding:0,
                '& .flag-select__btn:after':{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    top: 0,
                    margin: 'auto'
                }
            }
        }
    },
    editFormContainer:{
        paddingRight:'36px'
    }

});

export default styles;