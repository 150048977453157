import React, { Component, Fragment } from 'react';
// import styles from './styles';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {observable} from 'mobx';
import { observer } from 'mobx-react';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, Paper, TextField, Button, Modal, Slide, Backdrop } from '@material-ui/core';

const state = observable({
   isOpen:true
});

const styles = {

    container:{
        width: '813px',
        height:'100vh',
        margin:'auto',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#ffffff',
        display:'flex',
        float:'right',
        outline:'none'
    },
    closeBtnWind: {
        width:31,
        height:32,
        display:'flex',
        float:'right',
        borderRadius:7,
        marginRight:10,
        marginTop:8,
        backgroundColor:'#fff',
    },
    closeBtnCtnt: {
        color:'#232323',
        fontSize:20,
        paddingLeft:4,
    }
}

class CustomModal extends Component{

    toggleHandler = () => {
        // state.isOpen = !state.isOpen
        this.props.closeCallBack();
    };

    render(){
        //console.log("modal called");
        const { classes, component, isOpen, closeCallBack } = this.props;
        // const { isOpen } = state;
        return(
            <Fragment>
                <Modal aria-labelledby="modal-component" open={ isOpen }
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{
                           style:{
                               opacity:0.9  ,
                               backgroundColor:'#050505',
                           }}
                        }
                >
                    <Slide in={isOpen} direction={'left'} timeout={500}>
                        <div>
                            <div className={classes.container}>
                                { component }
                            </div>
                            <div className={classes.closeBtnWind} onClick={e=>closeCallBack()}>
                                <div className={classes.closeBtnCtnt} onClick={e=>closeCallBack()}><ClearIcon/></div>
                            </div>
                        </div>
                    </Slide>
                </Modal>
            </Fragment>
        );
    }
}

export default withStyles(styles)(observer(CustomModal));
