import React, { Component } from 'react';
import {Tooltip, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { removeSpace } from '../helper';

const styles = {
    customCell:{
        maxWidth: '160px',
        minWidth: '80px',
        fontSize: '14px',
        fontFamily: "Noto Sans",
        fontWeight: '400',
        color: '#5e5e5e'
    },
    tooltip:{
        background:'#fff',
        color:'#5e5e5e',
        borderRadius:'6px',
        boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.17)',
        minWidth:'100px',
        '& p':{
            fontSize: '13px',
            fontFamily: "Noto Sans",
            fontWeight: '400',
            color: '#5e5e5e',
            margin: 0,
            padding: '14px 5px',
            lineHeight: '21px',
            maxWidth:'170px'
        }
    },
    tooltipArrow:{
        color:'#fff',
    },
}

class TableCell extends Component{
    constructor(props){
        super(props);
        this.state = {
            disableTooltip:true,
            tablecellId:''
        }
        this.cellRef = React.createRef();
    }

    componentDidMount(){
        if (this.cellRef.current.scrollWidth > this.cellRef.current.clientWidth){
            this.setState({
                disableTooltip:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.item !== this.props.item){
            if (this.cellRef.current.scrollWidth > this.cellRef.current.clientWidth){
                this.setState({
                    disableTooltip:false
                })
            }
            else{
                this.setState({
                    disableTooltip:true
                })
            }
    
        }
    }
    render(){
        const{item,classes} = this.props;
        return(
            <Tooltip title={
                <p>{item}</p>
            }
            classes={{
                tooltip:classes.tooltip,
                arrow:classes.tooltipArrow
            }} 
            arrow
            disableHoverListener={this.state.disableTooltip}
             >
                <Typography ref={this.cellRef} tablecell={!!item?removeSpace(item):''} className={classes.customCell} noWrap>
                    {item}
                </Typography>
            </Tooltip>    
        )
    }

}
export default withStyles(styles)(TableCell);