import React, { Component,Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import loaderImg from '../assets/loader.gif';
import clsx from 'clsx';

const state = observable({
    open:false
});

const styles = {
    loaderContainer:{
        position:'fixed',
        top:0,
        bottom:0,
        left:0,
        right:0,
        zIndex:'99999',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        opacity: 0.9,
        backgroundColor: '#050505',
    }

}

class Loader extends Component{
    render(){
        const {classes} = this.props;
        return(
            <Fragment>
                {state.open?
                    <Grid className={classes.loaderContainer}>
                        <img src={loaderImg} className={classes.loader} alt="loader img not found" />
                    </Grid>
                :null}
            </Fragment>
        )
    }
}

export default withStyles(styles) (observer(Loader));

const loader = {
    show:()=>{
        state.open = true;
    },
    hide:()=>{
        state.open = false;
    }
}
export { loader };