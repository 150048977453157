import React,{ Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Uncheck from '../assets/uncheck.png';
import Check from '../assets/check.png';
import clsx from 'clsx';

const styles = {
    container:{
        position:'absolute',
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: '15px',
        width: '15px'
    },
    box:{
        height: '14px',
        width: '14px',
        position: 'absolute'
    },
    checkboxDisabled:{
        pointerEvents:'none',
        opacity:'0.4'
    }
}

class CheckBox extends Component{
    render(){
        const {classes,checked,change,disabled} = this.props;
        return(
            <div className={clsx(classes.container,{[classes.checkboxDisabled]:disabled})}>
            {
                checked
                ?   
                
                    <img onClick={change} src={Check} className={classes.box} alt="check not found" />
                
                :   
                    <img onClick={change} src={Uncheck} className={classes.box} alt="uncheck not found" />
                    
            }
            </div>
        )
    }
}

export default withStyles(styles)(CheckBox)